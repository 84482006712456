import React, {useEffect, useState} from "react";
import {Btn, Container} from "../Assets/css/Common";
import close from '../Assets/image/close.png';
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../Utils/Api";

function Member(props) {
    const navigate = useNavigate();
    const {setSaveModal, answers, type,} = props;
    const [name, setName] = useState('');
    const [hp, setHp] = useState('');
    const searchname = useState(sessionStorage.getItem('searchname'));
    const searchid = useState(sessionStorage.getItem('searchid'));
    const SurveyNum = useState(sessionStorage.getItem('surveyNum'));
    const CompanyNum = useState(sessionStorage.getItem('companyNum'));
    const [saveModal2, setSaveModal2] = useState(false);
    const [errorModal2, setErrorModal2] = useState(false);
    const [errormsg, setErrorMsg] = useState('');
    const transformData = (answers) => {
        // 결과를 담을 배열
        const result = [];

        if(answers?.length !== 0) {
            // answers 객체 순회
            for (const [pe_question_id, point] of Object.entries(answers)) {
                // 새로운 객체 생성 및 배열에 추가
                result.push({
                    gubun: 'PE',
                    point: parseInt(point),
                    pe_question_id: parseInt(pe_question_id),
                });
            }
        }else{
            result.push({
                gubun: 'PE',
                point: undefined,
                pe_question_id: undefined,
            });
        }

        return result;
    };

    const transformData2 = (answers) => {
        // 결과를 담을 배열
        const result = [];

        if(answers?.length !== 0) {
            // answers 객체 순회
            for (const [pe_question_id, point] of Object.entries(answers)) {
                // 새로운 객체 생성 및 배열에 추가
                result.push({
                    gubun: 'FE',
                    point: parseInt(point),
                    fe_question_id: parseInt(pe_question_id),
                });
            }
        }else{
            result.push({
                gubun: 'FE',
                point: undefined,
                fe_question_id: undefined,
            });
        }

        return result;
    };

    const transformData3 = (answers) => {
        // 결과를 담을 배열
        const result = answers.map(item => ({
            ...item,
            gubun: "SE"
        }));

        return result;
    };
    const SaveNext = async () => {

        const postData = {
            "survey_id": parseInt(SurveyNum?.[0]),
            "company_target_id": parseInt(searchid?.[0]),
            "rater_company_id": parseInt(CompanyNum?.[0]),
            "phone": hp,
            "name": name,
            "temporary": type === 1 ? transformData(answers) : type === 2 ? transformData2(answers) : transformData3(answers),
        };

        // console.log('postData',postData);

        try {
            const res = await API.post(`/users/survey/temporary-save`, postData);
            // console.log('서버 응답:', res);

            if (res.data.status === true) {
                sessionStorage.setItem('hp', hp);
                sessionStorage.setItem('name', name);
                sessionStorage.setItem('ArraySearchName', null);
                sessionStorage.setItem('BeforeData', null);
                setSaveModal2(true);
            } else {
                setErrorModal2(true);
                setErrorMsg(res.data?.msg);
            }
        } catch (error) {
            console.error('에러 발생:', error);
        }
    }
// console.log('wqe',errorModal2);
    const onCheck = async (e) => {
        if(name && hp) {
            SaveNext();
        } else {
            // console.log('ee');
        }
    }

    const CloseBtn = () => {
        setName('');
        setHp('');
        setSaveModal(false);
    }

    const SaveModal = () => {
        setSaveModal(false);
        setSaveModal2(false);
    }

    return (
        <div>
            <div className={`fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10 ${errorModal2 || saveModal2 ? 'hidden' : 'block'}`}>
                <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                <div className="bg-white absolute z-20 w-[90%] md:w-[372px] p-5 md:p-8 rounded-lg">
                    <div className="flex justify-end items-center pb-1" >
                        <img src={close} alt='취소' className="cursor-pointer md:w-auto w-5 md:h-auto h-5" onClick={CloseBtn}/>
                    </div>
                    <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">진행중인 평가 임시저장</p>
                    <p className="text-sm md:text-base text-[#475569] tracking-normal md:tracking-tighter text-center">임시저장을 하시려면 이름, 휴대폰번호를 입력 후 <br/>평가를 시작해 주세요.</p>
                    <p className="text-sm md:text-base text-[#7D1825] tracking-normal md:tracking-tighter text-center">이름과 휴대전화 번호는 임시저장 기능을 위해서만 <br/>활용되며, 수집·저장되지 않습니다.</p>
                    <div className="mt-4 md:mt-7">
                        <input type={"text"} placeholder={"이름"} value={name} onChange={(e) => setName(e.target.value)} className="border-b border-b-slate-300 w-full mb-6 text-sm md:text-base  pb-[6px]"/>
                        <div className="border-b border-b-slate-300 w-full mb-6 md:mb-8 pb-[6px]">
                            <input
                                type="text"
                                value={hp}
                                onChange={(e) => setHp(e.target.value.replace(/\D/g, '').slice(0, 11))}
                                maxLength="11"
                                placeholder="-빼고 휴대폰번호를 입력해주세요."
                                className="w-full text-sm md:text-base"
                            />
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        {/*<Btn onClick={CloseBtn} width={'49%'} padding={'10px 0'} background={'#fff'} border={'1px solid #CBD5E1'} color={'#475569'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} type={"button"}>취소</Btn>*/}
                        <Btn onClick={onCheck} width={'100%'} padding={'10px 0'} background={name && hp ? '#7D1825' : '#CBD5E1'} border={name && hp ? '1px solid #7D1825' : '1px solid #CBD5E1'} color={name && hp ? '#fff' : '#E6ECF2'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} type={"button"}>임시저장</Btn>
                    </div>
                </div>
            </div>
            {saveModal2 &&
                <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                    <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                    <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                        <div className="flex justify-end items-center pb-1" >
                            <img src={close} alt='취소' className="cursor-pointer md:w-auto w-5 md:h-auto h-5" onClick={SaveModal}/>
                        </div>
                        <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">평가 임시저장 완료</p>
                        <p className="pb-4 text-[#475569] text-sm md:text-base font-medium text-center"><span className="text-[#7D1825] font-bold">{searchname?.[0] || '주관식'}</span> 평가가<br />임시 저장 되었습니다.</p>
                        <div className="flex justify-between items-center">
                            {/*<Btn onClick={CloseBtn} width={'49%'} padding={'10px 0'} background={'#fff'} border={'1px solid #CBD5E1'} color={'#475569'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} type={"button"}>취소</Btn>*/}
                            <Btn onClick={SaveModal} width={'100%'} padding={'10px 0'} background={'#7D1825'} color={'#fff'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} type={"button"}>확인</Btn>
                        </div>
                        {/*<p className="pb-4 text-[#475569] text-sm md:text-base font-normal text-center">이름과 휴대전화 번호는 본인 확인을 위해서만<br />활용되며, 저장되지 않습니다.</p>*/}
                    </div>
                </div>
            }
            {errorModal2 &&
                <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                    <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                    <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                        <div className="flex justify-end items-center pb-1" >
                            <img src={close} alt='취소' onClick={() => setErrorModal2(false)} className="cursor-pointer md:w-auto w-5 md:h-auto h-5"/>
                        </div>
                        <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">이미 사용된 계정</p>
                        <p className="text-sm md:text-base text-slate-600 tracking-normal md:tracking-tighter text-center md:pb-10 pb-5" dangerouslySetInnerHTML={{__html: errormsg}} />
                        <div className="flex items-center justify-between mb-8">
                            <Btn onClick={() => setErrorModal2(false)} background={'#7D1825'} border={'1px solid #7D1825'} color={'#fff'} width={'100%'} padding={'10px 0'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'}>확인</Btn>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Member;
