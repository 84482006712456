import React, {useEffect, useState} from "react";
import {Container, Wrap3} from "../Assets/css/Common";

function Flowchart(props) {
    const {check} = props

    return (
        <Wrap3>
            <div className={`text-center border-b-[3px] ${check === 1 ? 'border-[#7D1825]' : 'border-[#CBD5E1]'} w-1/4 pb-2.5`}>
                <p className={`text-xs lg:text-base md:leading-3 lg:leading-3 ${check === 1 ? 'text-[#7D1825]' : 'text-[#CBD5E1]'} font-bold`}><span className="md:block hidden">개별 로펌에 대한</span><br />역량 평가</p>
            </div>
            <div className={`text-center border-b-[3px] ${check === 2 ? 'border-[#7D1825]' : 'border-[#CBD5E1]'} w-1/4 pb-2.5`}>
                <p className={`text-xs lg:text-base md:leading-3 lg:leading-3 ${check === 2 ? 'text-[#7D1825]' : 'text-[#CBD5E1]'} font-bold`}><span className="md:block hidden">개별 로펌에 대한</span><br />분야별 평가</p>
            </div>
            <div className={`text-center border-b-[3px] ${check === 3 ? 'border-[#7D1825]' : 'border-[#CBD5E1]'} w-1/4 pb-2.5`}>
                <p className={`text-xs lg:text-base md:leading-3 lg:leading-3 ${check === 3 ? 'text-[#7D1825]' : 'text-[#CBD5E1]'} font-bold`}><span className="md:block hidden">최고의 로펌 및 변호사를 선정하는</span><br />주관식 평가</p>
            </div>
            <div className={`text-center border-b-[3px] ${check === 4 ? 'border-[#7D1825]' : 'border-[#CBD5E1]'} w-1/4 pb-2.5`}>
                <p className={`text-xs lg:text-base md:leading-3 lg:leading-3 ${check === 4 ? 'text-[#7D1825]' : 'text-[#CBD5E1]'} font-bold`}><span className="md:block hidden">모든 로펌에 대한</span><br />평가 완료</p>
            </div>
        </Wrap3>
    );
}

export default Flowchart;
