import React, {useState, useEffect} from 'react';
import {Radio} from "../../Assets/css/Common";

const Evaluation1 = ({number, title, name, onAnswerChange, point }) => {
    const [checkedValue, setCheckedValue] = useState(point); // State to manage checked value

    const options = [
        { id: 'option1', label: '매우 그렇지 않다.', value: 1 },
        { id: 'option2', label: '그렇지 않다.', value: 2 },
        { id: 'option3', label: '보통이다.', value: 3 },
        { id: 'option4', label: '그렇다.', value: 4 },
        { id: 'option5', label: '매우 그렇다.', value: 5 },
    ];

    const handleRadioChange = (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        setCheckedValue(selectedValue);
        onAnswerChange(name, selectedValue);
    };

    return (
        <div className="flex md:flex-row flex-col items-center">
            {options.reverse().map((option) => (
                <div key={option.id} className="md:w-3/12 w-full">
                    <Radio
                        type={"radio"}
                        id={name+option.id}
                        name={name}
                        value={option.value}
                        checked={checkedValue === option.value}
                        onChange={handleRadioChange}
                        className="mr-2 cursor-pointer"
                    />
                    <label htmlFor={name+option.id} style={{cursor:'pointer'}} className={`text-xs md:text-base text-[#475569] relative top-[1px]`}>{option.label}</label>
                </div>
            ))}
        </div>
    );
};

const Evaluation2 = ({number, title, name, onAnswerChange, point }) => {
    const [checkedValue, setCheckedValue] = useState(point); // State to manage checked value

    useEffect(() => {
        setCheckedValue(point);
    }, [point]);

    const options = [
        { id: 'option1', label: '불량', value: 1 },
        { id: 'option2', label: '미흡', value: 2 },
        { id: 'option3', label: '보통', value: 3 },
        { id: 'option4', label: '우수', value: 4 },
        { id: 'option5', label: '탁월', value: 5 },
    ];

    const handleRadioChange = (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        setCheckedValue(selectedValue);
        onAnswerChange(name, selectedValue);
    };

    return (
        <div className="flex md:flex-row flex-col items-center">
            {options.reverse().map((option) => (
                <div key={option.id} className="md:w-3/12 w-full">
                    <Radio
                        type={"radio"}
                        id={name+option.id}
                        name={name}
                        value={option.value}
                        checked={checkedValue === option.value}
                        onChange={handleRadioChange}
                        className="mr-2"
                    />
                    <label htmlFor={name+option.id} style={{cursor:'pointer'}} className={`text-xs md:text-base text-[#475569]`}>{option.label}</label>
                </div>
            ))}
        </div>
    );
};

export {Evaluation1,Evaluation2};
