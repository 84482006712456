import React, {useEffect, useState} from "react";
import Head from '../../Components/Head';
import {Btn, Container, Wrap, Wrap2, Wrap3} from "../../Assets/css/Common";
import Evaluation_1 from "./Evaluation_1";
import Evaluation_2 from "./Evaluation_2";
import close from "../../Assets/image/close.png";
import {useLocation, useNavigate} from "react-router-dom";
import {API} from "../../Utils/Api";
import History from "../../Components/History";
import Flowchart from "../../Components/Flowchart";
import LinkError from "../../Components/LinkError";

function Evaluation() {
    const navigate = useNavigate();
    const location = useLocation();
    const state = { ...location.state };
    const [next, setNext] = useState(state?.next ? state?.next : 1);
    const [searchname, setSearchName] = useState(sessionStorage.getItem('searchname'));
    const [searchid, setSearchId] = useState(sessionStorage.getItem('searchid'));
    const [hp, setHp] = useState(sessionStorage.getItem('hp'));
    const [name, setName] = useState(sessionStorage.getItem('name'));
    const [SurveyNum, setSurveyNum] = useState(sessionStorage.getItem('surveyNum'));
    const [CompanyNum, setCompanyNum] = useState(sessionStorage.getItem('companyNum'));
    const [storedData, setstoredData] = useState(JSON.parse(sessionStorage.getItem('ArraySearchName')) || []);
    const [dataArray, setDataArray] = useState(storedData);
    const [answers, setAnswers] = useState([]);
    const [answers2, setAnswers2] = useState([]);
    const [saveModal, setSaveModal] = useState(false);
    const [submitModal, setSubmitModal] = useState(false);
    const [before, setBefore] = useState(0);

    // 변환 함수
    const transformData = (answers) => {
        // 결과를 담을 배열
        const result = [];

        // answers 객체 순회
        if(answers?.length !== 0) {
            for (const [pe_question_id, point] of Object.entries(answers)) {
                // 새로운 객체 생성 및 배열에 추가
                result.push({
                    gubun: 'PE',
                    point: parseInt(point),
                    pe_question_id: parseInt(pe_question_id),
                    // fe_question_id: null,
                });
            }
        }else{
            result.push({
                gubun: 'PE',
                point: undefined,
                pe_question_id: undefined,
                // fe_question_id: null,
            });
        }

        return result;
    };

    const transformData2 = (answers) => {
        // 결과를 담을 배열
        const result = [];

        if(answers?.length !== 0) {
            // answers 객체 순회
            for (const [pe_question_id, point] of Object.entries(answers)) {
                // 새로운 객체 생성 및 배열에 추가
                result.push({
                    gubun: 'FE',
                    point: parseInt(point),
                    // pe_question_id: null,
                    fe_question_id: parseInt(pe_question_id),
                });
            }
        }else{
            result.push({
                gubun: 'FE',
                point: undefined,
                // pe_question_id: null,
                fe_question_id: undefined,
            });
        }

        return result;
    };

    const NextSubmit = async (e,type) => {
        const a = transformData(answers);
        const b = transformData2(answers2);

        const Array = a.concat(b);

        // console.log(postData);
        const newData = {
            law_firm_name: searchname,
        };
        const updatedArray = [...dataArray, newData];
        setDataArray(dataArray);

        if (e === 'TemporaryStorage') {
            const postData = {
                "survey_id": parseInt(SurveyNum),
                "company_target_id": parseInt(searchid),
                "rater_company_id": parseInt(CompanyNum),
                "phone": hp,
                "name": name,
                "temporary": next === 1 ? a : b,
            };

            try {
                const res = await API.post(`/users/survey/temporary-save`, postData);
                // console.log('서버 응답2:', res);

                if (res.data.status === true) {
                    if (e === 'TemporaryStorage') {
                        // sessionStorage.setItem('ArraySearchName', null);
                        if(type !== 'page') {
                            setSaveModal(true);
                        }
                    } else {
                        console.log('error');
                        // sessionStorage.setItem('ArraySearchName', null);
                    }
                } else {
                    console.log('error');
                }
            } catch (error) {
                console.error('에러 발생:', error);
            }
        }else{
            const postData = {
                "survey_id": parseInt(SurveyNum),
                "company_target_id": parseInt(searchid),
                "rater_company_id":CompanyNum,
                "phone": hp,
                "name": name,
                "answer": Array,
            };

            try {
                const res = await API.post(`/users/survey/answer-submit`, postData);
                // console.log('서버 응답213:', res);
                if (e === 'SaveSubmit') {
                    sessionStorage.setItem('searchid', '');
                    sessionStorage.setItem('searchname', '');
                    sessionStorage.setItem('ArraySearchName', JSON.stringify(updatedArray));
                    NextSubmit('TemporaryStorage','page');
                    navigate('/search');
                } else if (e === 'Submit') {
                    sessionStorage.setItem('searchid', '');
                    sessionStorage.setItem('searchname', '');
                    sessionStorage.setItem('ArraySearchName', JSON.stringify(updatedArray));
                    NextSubmit('TemporaryStorage','page');
                    navigate('/result');
                } else {
                    console.log('error');
                    // sessionStorage.setItem('ArraySearchName', null);
                }
            } catch (error) {
                console.error('에러 발생:', error);
            }
        }
    };

    const SaveSubmit = () => {
        // navigate(`/survey/${SurveyNum?.[0]}/${CompanyNum?.[0]}`);
        setSaveModal(false);
    }

    return (
        <>
        {sessionStorage.getItem('pageStatus') === '' ?
        <div>
            <Head />
            <Flowchart check={next}/>
            <Wrap>
                <>
                {next !== 2 ?
                    <Evaluation_1 NextSubmit={NextSubmit} transformData={transformData} searchid={searchid} hp={hp} setHp={setHp} setName={setName} name={name} setNext={setNext} searchname={searchname} SurveyNum={SurveyNum} CompanyNum={CompanyNum} setAnswers={setAnswers} setSearchName={setSearchName} setSearchId={setSearchId} answers={answers} before={before} setBefore={setBefore}/>
                :
                    <Evaluation_2 NextSubmit={NextSubmit} hp={hp} setHp={setHp} setName={setName} name={name} setNext={setNext} setSubmitModal={setSubmitModal} searchname={searchname} SurveyNum={SurveyNum} CompanyNum={CompanyNum} setSearchName={setSearchName} setSearchId={setSearchId} setAnswers={setAnswers2} answers={answers2} setBefore={setBefore}/>
                }

                {saveModal &&
                    <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                        <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                            <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                            <div className="flex justify-end items-center pb-1" >
                            <img src={close} alt='취소' className="cursor-pointer md:w-auto w-5 md:h-auto h-5" onClick={SaveSubmit}/>
                            </div>
                            <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">평가 임시저장 완료</p>
                            <p className="pb-4 text-[#475569] text-sm md:text-base font-medium text-center"><span className="text-[#7D1825] font-bold">{searchname}</span> 평가가<br />임시 저장 되었습니다.</p>
                            <div className="flex justify-between items-center">
                                <Btn onClick={SaveSubmit} width={'100%'} padding={'10px 0'} background={'#7D1825'} color={'#fff'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} type={"button"}>확인</Btn>
                            </div>
                        </div>
                    </div>
                }

                {submitModal &&
                    <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                        <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                        <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                            <div className="flex justify-end items-center pb-1" >
                                <img src={close} alt='취소' onClick={() => setSubmitModal(false)} className="cursor-pointer md:w-auto w-5 md:h-auto h-5"/>
                            </div>
                            <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">개별 평가 완료</p>
                            <p className="text-[#475569] text-sm md:text-base font-medium text-center"><span className="text-[#7D1825] font-bold">{searchname}</span>의 평가가 <br className="md:hidden visible"/>완료되었습니다.</p>
                            <p className="text-[#475569] text-sm md:text-base font-medium text-center">다른 로펌도 평가하시겠습니까?</p>
                           <div className="pt-5 md:pt-10">
                                <Btn onClick={() => NextSubmit('SaveSubmit')} background={'#F5E9EB'} color={'#7D1825'} width={'100%'} padding={'14px 0'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} margin={'0 0 10px 0'}>다른 로펌의 개별 평가하러 가기</Btn>
                                <Btn onClick={() => NextSubmit('Submit')} background={'#7D1825'} color={'#fff'} width={'100%'} padding={'14px 0'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'}>3단계 주관식 평가하러 가기<br/><span className="font-medium text-xs pt-3 block">ⓘ 주관식 평가 진행 시 개별 로펌평가가 종료되며,<br/>이전 단계 페이지로 돌아갈 수 없습니다.</span></Btn>
                            </div>
                        </div>
                    </div>
                }
                </>
                <History />
            </Wrap>
            <div className="w-full text-center pb-4">
                <p className="md:text-sm text-[10px] text-[#4E5968]">Copyright ⓒ 2024 law firm rating. All Rights Reserved</p>
            </div>
        </div>
            :
            <LinkError />
        }
        </>
    );
}

export default Evaluation;
