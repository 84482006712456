import React, {useEffect, useState} from "react";
import icon from '../Assets/image/save.png';

function TemporaryStorage(props) {
    const {NextSave} = props;

    return (
        <>
            <div className="flex justify-end">
                <div onClick={NextSave} className="z-0 w-[50px] md:w-[80px] h-[50px] md:h-[80px] fixed bottom-20 md:bottom-28 mr-0 md:-mr-28 flex flex-col justify-center items-center bg-[#0F172A] rounded-full cursor-pointer">
                    <img src={icon} alt={'history'} className="w-[18px] md:w-[25px]"/>
                    <p className="text-white text-[9px] md:text-xs font-medium text-center pt-1">임시저장</p>
                </div>
            </div>
        </>

    );
}

export default TemporaryStorage;
