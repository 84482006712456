import React, {useEffect, useState} from "react";
import {Container} from "../Assets/css/Common";
import logo from '../Assets/image/logo_3.png';
import logo_mo from '../Assets/image/logo_2.png';
import {createBrowserHistory} from "history";
import {useLocation, useNavigate} from "react-router-dom";
function Head() {

    return (
        <div className="bg-white border-b border-b-slate-300 flex items-center md:h-20 h-16">
            <Container>
                <div className="flex justify-between items-center">
                    <img src={logo} alt={'logo'} className="hidden md:block w-[102px]" />
                    <img src={logo_mo} alt={'logo'} className="block md:hidden w-[77px]" />
                    <p className="font-medium text-[#475569] md:text-base text-xs">본 설문지의 <span className="font-[600] text-[#7D1825]">외부 유출 및 공유 금지</span></p>
                </div>
            </Container>
        </div>
    );
}

export default Head;
