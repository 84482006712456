import React, {useEffect, useState } from "react";
import {Btn} from "../../Assets/css/Common";
import {Evaluation1} from "./EvaluationData";
import { API } from "../../Utils/Api";
import close from "../../Assets/image/close.png";
import Member from "../../Components/Member";
import TemporaryStorage from "../../Components/TemporaryStorage";
import {useLocation, useNavigate} from "react-router-dom";
import {createBrowserHistory} from "history";
const history = createBrowserHistory();

function Evaluation_1(props) {
    const {hp, setHp, name, setName, searchname, SurveyNum, searchid, transformData, CompanyNum, setSearchName, setSearchId, answers, setAnswers, NextSubmit, before, setBefore} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [item, setItem] = useState([]);
    const [pageStatus,setPageStatus] = useState(true);
    const [unansweredQuestions, setUnansweredQuestions] = useState([]);
    const [errorModal, setErrorModal] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const [gubun, setGubun] = useState(sessionStorage.getItem('gubun') || 1);

    useEffect(() => {
        const handleNavigation = (action) => {
            if (action === "POP") {
                if(window.confirm('페이지를 벗어나면 작성된 내용은 저장되지 않습니다.\n이 페이지에서 벗어나시겠습니까?')){
                    navigate('/');
                } else {
                    props.setNext(1);
                    navigate(location.pathname, { replace: false});
                }
            }
        };

        const unlisten = history.listen(({ action }) => handleNavigation(action));

        return () => unlisten();
    }, [navigate, history]);

    useEffect(() => {
        setHp(sessionStorage.getItem('hp'));
        setName(sessionStorage.getItem('name'));
    },[saveModal]);

    // console.log(saveModal);
    // console.log(hp);
    // console.log(name);

    const handleAnswerChange = (questionId, value) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: value,
        }));
    };

    const getData = async () => {
        const res = await API.get(`/users/survey-question/${SurveyNum}`);
        if(res.data.status === true){
            setData(res.data?.result?.pe_questions);
            setPageStatus(res.data.status);
        }else{
            setPageStatus(res.data.status);
        }
        // console.log(res.data?.result?.pe_questions);
    };

    const getData2 = async () => {
        const res = await API.get(`/users/survey/onProgress-evaluation/${SurveyNum}?name=${name}&phone=${hp}&rater_company_id=${CompanyNum}`);
       // console.log('임시저장불러오기', res);
        if (res.data?.result?.length !== 0) {
            if (res.data?.result?.[0]?.gubun === gubun) {
                sessionStorage.setItem('gubun', 0);
                setGubun(0);
                if (!sessionStorage.getItem('searchid') || sessionStorage.getItem('searchid') === null) {
                    //   if(sessionStorage.getItem('searchid') === null) {
                    setSearchName(res.data?.result?.[0]?.company_target.law_firm_name);
                    setSearchId(res.data?.result?.[0]?.company_target_id);
                    setItem(res.data?.result?.[0]?.temporary_storage_histories);
                    const initialAnswers = {};
                    res.data?.result?.[0]?.temporary_storage_histories.forEach(item2 => {
                        initialAnswers[item2.pe_question_id] = item2.point;
                    });

                    // console.log('initialAnswers',initialAnswers)
                    setAnswers(initialAnswers);
                }
            }

        }
        // console.log(res.data?.result?.pe_questions);
    };

    useEffect(() => {
        if(gubun === 1) {
            getData2();
        }
        getData();
    },[]);


    const isAllAnswersSelected = () => {
        return data.every(question => answers[question.id] !== undefined);
    };

    const OnNext = () => {
        const unansweredKeys = data
            .map((question, key) => ({ key, answered: answers[question.id] !== undefined }))
            .filter(q => !q.answered)
            .map(q => q.key + 1);

        if (unansweredKeys.length === 0) {
            NextPage();
        } else {
            setUnansweredQuestions(unansweredKeys);
            setErrorModal(true);
        }
    }

    const NextSave = () => {
        if (hp !== '' && name !== '') {
            NextSubmit('TemporaryStorage');
        }else{
            setSaveModal(true);
        }
    }

    const NextPage = () => {
        if(hp !== '' && name !== '') {
            NextSubmit('TemporaryStorage','page');
        }else{
            const a = transformData(answers);
            const postData = {
                "survey_id": parseInt(SurveyNum),
                "company_target_id": parseInt(searchid),
                "rater_company_id": parseInt(CompanyNum),
                "law_firm_name": searchname,
                "phone": hp,
                "name": name,
                "temporary": a,
            };

            const beforeData = JSON.stringify(postData);
            sessionStorage.setItem('BeforeData',beforeData);
            // console.log('wqewq',postData);
        }
        props.setNext(2);
        setBefore(0);
    }

    const Before = () => {
        navigate('/search');
        setBefore(0);
    }

    // 이전데이터
    const getData3 = async () => {
        const res = await API.get(`/users/survey/get-history-back/${SurveyNum}?name=${name}&phone=${hp}&rater_company_id=${CompanyNum}`);
        // console.log('이전데이터불러오기',res);
        if (res.data?.result?.length !== 0) {
            //   if(sessionStorage.getItem('searchid') === null) {
            setSearchName(res.data?.result?.[0]?.company_target.law_firm_name);
            setSearchId(res.data?.result?.[0]?.company_target_id);
            setItem(res.data?.result?.[0]?.temporary_storage_histories);
            const initialAnswers = {};
            res.data?.result?.[0]?.temporary_storage_histories.forEach(item2 => {
                initialAnswers[item2.pe_question_id] = item2.point;
            });
            setAnswers(initialAnswers);
        }
    };

    const NoUserData = () => {
        const storedDataString = JSON.parse(sessionStorage.getItem('BeforeData'));
        if(storedDataString?.temporary.length !== 0){
            setSearchName(storedDataString?.law_firm_name);
            setSearchId(storedDataString?.company_target_id);
            setItem(storedDataString?.temporary);
            const initialAnswers = {};
            storedDataString?.temporary.forEach(item2 => {
                initialAnswers[item2.pe_question_id] = item2.point;
            });
            setAnswers(initialAnswers);
        }
    };

    useEffect(() => {
        if(before === 1){
            if(hp !== '' && name !== ''){
                getData3();
            }else{
                NoUserData();
            }
        }
    },[before,hp,name]);

    // console.log(answers.length !== 0);

    return (
        <>
            {pageStatus === true &&
            <div className="md:p-10 p-4">
                <p className="text-[1.125rem] md:text-xl text-[#7D1825] font-bold pb-2.5 md:pb-2.5">{searchname}</p>
                {/*<p className="text-xl md:text-2xl text-slate-900 font-normal pb-2">1. 역량 평가</p>*/}
                <div className="flex md:flex-row flex-col md:items-start md:justify-between pb-5 md:pb-10 border-b border-b-slate-300 mb-5 md:mb-10">
                    <p className="text-sm md:text-base text-[#4E5968] font-normal pb-4 md:pb-0">아래 문항을 읽고 5점 척도로 평가해
                        주세요.</p>
                    <p className="text-xs md:text-base text-[#4E5968] leading-6 md:leading-0"><span
                        className="font-bold">5</span>: 매우 그렇다<span className="text-[#D5DADE] px-[3px] md:px-[8px]">|</span><span
                        className="font-bold">4</span>: 그렇다<span className="text-[#D5DADE] px-[3px] md:px-[8px]">|</span><br
                        className="md:hidden"/><span className="font-bold">3</span>: 보통이다<span
                        className="text-[#D5DADE] px-[3px] md:px-[8px]">|</span><span className="font-bold">2</span>: 그렇지 않다<span
                        className="text-[#D5DADE] px-[3px] md:px-[8px]">|</span><span className="font-bold">1</span>: 매우 그렇지 않다</p>
                </div>
                {data.map((list, key) => {
                    // console.log(item);
                    const correspondingAnswer = item?.find(item2 => item2.pe_question_id === list?.id);
                    return (
                        <div className="pb-10" key={key}>
                            <p className="font-normal text-sm md:text-xl text-slate-900 pb-2">{key + 1}. <span
                                dangerouslySetInnerHTML={{__html: list.question}}/></p>
                            <Evaluation1 name={list.id} onAnswerChange={handleAnswerChange} point={correspondingAnswer ? correspondingAnswer.point : null}/>
                        </div>
                    );
                })}
                <div className="flex items-center justify-center">
                    {/*<Btn onClick={NextSave} background={hp?.[0] === '' && name?.[0] === '' ? '#94A3B8' : '#fff'} border={'1px solid #CBD5E1'} color={hp?.[0] === '' && name?.[0] === '' ? '#CBD5E1' :'#475569'} width={'200px'} widthmd={'100%'} padding={'14px 0'} radius={'6px'} size={'1rem'} sizemd={'1rem'} weight={'700'} margin={'0 16px 0 0'}>임시 저장</Btn>*/}
                    <Btn onClick={Before} background={'#fff'} border={'1px solid #CBD5E1'} color={'#475569'} width={'200px'} widthmd={'100%'} padding={'14px 0'} radius={'6px'} size={'1rem'} sizemd={'1rem'} weight={'700'} margin={'0 10px 0 0'}>이전</Btn>
                    <Btn onClick={OnNext} width={'200px'} widthmd={'100%'} padding={'14px 0'}
                         background={`${answers?.length !== 0 ? '#7D1825' : '#CBD5E1'}`} border={`${answers?.length !== 0 ? '1px solid #7D1825' : '1px solid #CBD5E1'}`} color={`${answers?.length !== 0 ? '#fff' : '#E6ECF2'}`} radius={'6px'} size={'1rem'} sizemd={'0.875rem'}
                         weight={'700'}>다음</Btn>
                </div>
            </div>
            }

            {errorModal &&
                <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                    <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                    <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                        <div className="flex justify-end items-center pb-1" >
                            <img src={close} alt='취소' className="cursor-pointer md:w-auto w-5 md:h-auto h-5" onClick={() => setErrorModal(false)}/>
                        </div>
                        <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">미응답 항목 안내</p>
                        <p className="pb-6 text-slate-900 text-sm md:text-base font-medium text-center tracking-normal md:tracking-tighter">{unansweredQuestions.join(', ')}번<br /> 문항에 응답하지 않으셨습니다.</p>
                        <div className="flex items-center flex-col justify-between">
                            <Btn onClick={() => setErrorModal(false)} background={'#fff'} border={'1px solid #CBD5E1'} color={'#475569'} width={'100%'} padding={'14px 0'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} margin={'0 0 10px'}>추가 응답하기</Btn>
                            <Btn onClick={NextPage} background={'#7D1825'} border={'1px solid #7D1825'} color={'#fff'} width={'100%'} padding={'14px 0'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'}>다음평가로 넘어가기</Btn>
                        </div>
                   </div>
                </div>
            }
            {saveModal &&
                <Member setSaveModal={setSaveModal} answers={answers} type={1}/>
            }
            <TemporaryStorage NextSave={NextSave}/>
        </>
    );
}

export default Evaluation_1;
