import React, {useEffect, useState } from "react";
import {Btn} from "../../Assets/css/Common";
import {API} from "../../Utils/Api";
import search from "../../Assets/image/search.png";
import on from "../../Assets/image/checkon2.png";
import off from "../../Assets/image/checkoff.png";
import Member from "../../Components/Member";
import close from "../../Assets/image/close.png";
import TemporaryStorage from "../../Components/TemporaryStorage";

function Result_1(props) {
    const {hp, setHp, name, setName, SurveyNum, CompanyNum, answers, setAnswers, NextSubmit, handleSubmit} = props;
    const [data, setData] = useState([]);
    const [item, setItem] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [originalSearchData, setOriginalSearchData] = useState([]); // 추가: 원본 검색 데이터
    const [pageStatus,setPageStatus] = useState(true);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isFocused, setIsFocused] = useState(false);
    const [focusedInput, setFocusedInput] = useState(null);
    const [saveModal, setSaveModal] = useState(false);
    const [nextModal, setNextModal] = useState(false);
    const [gubun, setGubun] = useState(sessionStorage.getItem('gubun') || 1);
    const [valueCheck, setValueCheck] = useState(false);

    useEffect(() => {
        setHp(sessionStorage.getItem('hp'));
        setName(sessionStorage.getItem('name'));
    },[saveModal])

    const getData = async () => {
        const res = await API.get(`/users/survey-question/${SurveyNum}`);
        if(res.data.status === true){
            setData(res.data?.result?.se_questions);
            // console.log(res.data?.result?.se_questions);
            const transformed = transformQuestions(res.data?.result?.se_questions);
            setAnswers(transformed);
            // console.log('ㅂㅈ6',transformed);
        }else{
            setPageStatus(res.data.status);
        }
    };

    const transformQuestions = (questions) => {
        return questions.reduce((acc, question) => {
            const answersForQuestion = Array.from({ length: question.answer_cnt }, (_, index) => ({
                answer_name: "",
                answer_reason: "",
                se_question_id: question.id,
            }));
            return [...acc, ...answersForQuestion];
        }, []);
    };

    const getData2 = async () => {
        const res = await API.get(`/users/survey/law-firm`);
        // console.log('res',res);
        if(res.data.status === true) {
            setSearchResults(res.data?.result);
            setOriginalSearchData(res.data?.result);
        }else{
            console.log('error')
        }
    };

    const getData3 = async () => {
        const res = await API.get(`/users/survey/onProgress-evaluation/${SurveyNum}?name=${name}&phone=${hp}&rater_company_id=${CompanyNum}`);
         // console.log('임시저장불러오기', res);
        // console.log('gubun', gubun);
        if (res.data?.result?.length !== 0) {
            if (res.data?.result?.[0]?.gubun === gubun) {
                sessionStorage.setItem('gubun', 0);
                setGubun(0);
                // console.log('임시저장',res.data?.result.length);
                sessionStorage.setItem('searchid', res.data?.result?.[0]?.company_target_id);
                setItem(res.data?.result?.[0]?.temporary_storage_histories);
                // console.log(res.data?.result?.[0]?.temporary_storage_histories);
            }
        }
    };
    useEffect(() => {
        getData();
    },[]);

    useEffect(() => {
        getData2();
    },[focusedInput, isFocused]);

    useEffect(() => {
        getData3();
    },[data]);

    useEffect(() => {
        // console.log('item',item);
        if (item && item.length > 0) {
            // console.log('item',item);
            const updatedAnswers = item?.map(item2 => ({
                answer_name: item2.answer_name || '',
                answer_reason: item2.answer_reason || '',
                se_question_id: item2.se_question_id || ''
            }));

            // 현재 `answers` 배열의 길이에 따라 누락된 항목을 추가
            // const maxCount = data.reduce((max, question) => Math.max(max, question.answer_cnt), 0);
            // for (let i = updatedAnswers.length; i < maxCount * data.length; i++) {
            //     updatedAnswers.push({ answer_name: '', answer_reason: '', se_question_id: Math.floor(i / maxCount) + 1 });
            // }
            // console.log('updatedAnswers',updatedAnswers);
            setAnswers(updatedAnswers);
            // console.log('ㅂㅈ4',answers);
        }
    },[item])
    // console.log('answer123s',answers);
    const handleInputChange = (index, field, value) => {
        // console.log('value',value);
        const maxLength = index % 2 !== 0 ? 20 : 15;
        // answers 배열의 index 위치에 해당하는 값을 변경
        if (field === 'answer_reason' || value.length <= maxLength) {
            // if (focusedInput === index && index % 2 === 0) {
                const filteredResults = originalSearchData.filter(item =>
                    item?.law_firm_name.toLowerCase().includes(value.toLowerCase())
                );
                setSearchResults(filteredResults);
            // }
            // console.log('answers44',answers);
            // console.log(index);
            const newAnswers = [...answers];
            // console.log('newAnswers',newAnswers);
            newAnswers[index] = {
                ...newAnswers[index], // 이전 값을 복사
                [field]: value, // 필드에 따라 해당 값을 설정
            };
            setAnswers(newAnswers);
            // console.log('ㅂㅈ2',newAnswers);
        }
    };

    // 입력 필드 포커스 이벤트 핸들러
    const handleFocus = (inputId) => {
        setFocusedInput(inputId);
        setIsFocused(true);
    };

    // 입력 필드 포커스 해제 이벤트 핸들러
    const handleBlur = () => {
        setFocusedInput(null);
        setIsFocused(false);
    };

    const handleSelect = (index, field, value) => {
        // console.log('qwe',index+','+field+','+value);
        handleInputChange(index, field, value);
        // setSearchTerm(value);
        // setSearchId(id);
        setIsFocused(false); // 리스트를 닫음
        setHoveredIndex('');
    };

    const handleNext = async () => {
        setNextModal(true);
    };

    const NextSave = () => {
        if(hp?.[0] !== '' && name?.[0] !== ''){
            NextSubmit('TemporaryStorage');
        }else{
            setSaveModal(true);
        }
    }

    const CheckValue = () => {
        const hasData = answers?.some(item => item.answer_name !== '' || item.answer_reason !== '');
        setValueCheck(hasData);
    };

    useEffect(() => {
        CheckValue();
    },[answers])
    // console.log('ㅂㅈㄷ',CheckValue);

    return (
        <>
            {pageStatus === true &&
                <div className="md:p-10 p-4">
                    {/*<p className="text-3xl md:text-4xl text-[#AE3A1E] font-bold pb-2 md:pb-4">3단계 진행 중 (마지막 단계)</p>*/}
                    <p className="text-[1.125rem] md:text-xl text-[#7D1825] font-bold pb-2.5 md:pb-2.5">최고의 로펌과 최고의 변호사 선정</p>
                    {/*<p className="text-xl md:text-2xl text-slate-900 font-normal pb-2">3. 주관식 평가</p>*/}
                    <div className="pb-5 md:pb-10 border-b border-b-slate-300 mb-5 md:mb-10">
                        <p className="text-sm md:text-base text-slate-900 font-normal">3단계는 전체 로펌과 변호사에 대한 평가로, 올해 경험한 로펌과 변호사를 기준으로 아래 문항에 응답해주세요.<br />
                            최대 3개까지의 복수 응답이 가능하며, 응답하지 않고도 설문을 완료할 수 있습니다.</p>
                    </div>
                    {data?.map((list, key) => {
                        let correspondingAnswers = item ? item.filter(item2 => item2.se_question_id === list.id) : [];

                        return (
                            <div className={`pb-5 md:pb-10 mb-5 md:mb-10 ${key !== data.length - 1 ? 'border-b border-[#CBD5E1]' : ''}`} key={key}>
                                <p className="font-normal text-sm md:text-xl text-slate-900 pb-2 md:pb-5">{key + 1}. <span
                                    dangerouslySetInnerHTML={{__html: list.question}}/></p>
                                {Array?.from({length: key === data.length - 1 ? 1 : list.answer_cnt})?.map((_, subKey) => {
                                    let answer = correspondingAnswers[subKey]
                                        ? correspondingAnswers[subKey]
                                        : { answer_name: "",
                                            answer_reason: "",
                                            fe_question_id: null,
                                            gubun: "SE",
                                            index: subKey+1,
                                            pe_question_id: null,
                                            point: null,
                                            se_question_id: key+1 };

                                    // console.log('answer'+(subKey+1),answers);
                                    return (
                                    <div className={`${subKey !== 2 ? 'mb-3' : ''}`} key={subKey}>
                                        {key !== data.length - 1 &&
                                            <div>
                                                {key % 2 !== 0 ?
                                                    <div
                                                        className="flex items-center border-slate-300 border w-full py-2.5 md:py-4 px-2.5 md:px-5 mb-4 relative bg-white">
                                                        {/* <p>{subKey+1}.</p> */}
                                                        <input
                                                            type={"text"}
                                                            value={answers[key * list.answer_cnt + subKey]?.answer_name || answer?.answer_name || ""}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    key * list.answer_cnt + subKey,
                                                                    "answer_name",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder={`${key % 2 !== 0 ? '변호사 이름과 소속 로펌명을 작성해 주세요.' : '로펌 이름을 작성해 주세요.'}`}
                                                            onFocus={handleBlur}
                                                            className="text-xs md:text-base w-full"
                                                        />
                                                        <span className="text-xs md:text-sm">
                                                            {`${(answers[key * list.answer_cnt + subKey]?.answer_name || answer?.answer_name || "").length}/${key % 2 !== 0 ? 20 : 15}`}
                                                        </span>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div
                                                            className="flex items-center border-slate-300 border w-full py-2.5 md:py-4 px-2.5 md:px-5 mb-4 relative bg-[#F8FAFC]">
                                                            <input
                                                                type="text"
                                                                placeholder="로펌명을 입력해 주세요."
                                                                value={answers[key * list.answer_cnt + subKey]?.answer_name || answer?.answer_name || ""}
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        key * list.answer_cnt + subKey,
                                                                        "answer_name",
                                                                        e.target.value
                                                                    )}
                                                                onFocus={() => handleFocus(key * list.answer_cnt + subKey)}
                                                                // onBlur={handleBlur}
                                                                className="text-xs md:text-base w-full bg-transparent"
                                                            />
                                                            <img src={search} alt={"검색"}
                                                                 className="w-5 md:w-5"/>
                                                        </div>
                                                        {answers[key * list.answer_cnt + subKey]?.answer_name && focusedInput === key * list.answer_cnt + subKey && isFocused && (
                                                            <ul className="w-full overflow-x-auto scrollbar-hide h-24 md:h-52 mb-4 border">
                                                                {searchResults.map((result, index) => (
                                                                    <li key={index}
                                                                        onClick={() => handleSelect(key * list.answer_cnt + subKey, "answer_name", result?.law_firm_name)}
                                                                        onMouseEnter={() => setHoveredIndex(index)}
                                                                        onMouseLeave={() => setHoveredIndex(null)}
                                                                        className="flex justify-between items-center px-2 md:px-4 md:py-2 hover:bg-slate-300">
                                                                        <p className="text-xs md:text-base">{result?.law_firm_name}</p>
                                                                        <img src={hoveredIndex === index ? on : off}
                                                                             alt={'check'} style={{
                                                                            width: 20,
                                                                            height: 20,
                                                                            objectFit: 'none'
                                                                        }}/>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <textarea
                                            value={answers[key * 3 + subKey]?.answer_reason || answer?.answer_reason || ""}
                                            // value={answers[key * 3 + subKey]?.answer_reason || answer?.answer_reason || ''}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    key * 3 + subKey,
                                                    "answer_reason",
                                                    e.target.value
                                                )
                                            }
                                            placeholder={"이유를 작성해 주세요."}
                                            onFocus={handleBlur}
                                            className="text-xs md:text-base border-slate-300 border w-full py-2.5 md:py-4 px-2.5 md:px-5 h-24"
                                        />
                                    </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                    <div className="flex items-center justify-center">
                        <Btn onClick={handleSubmit} width={'200px'} widthmd={'100%'} padding={'14px 0'} background={`#0F172A`} color={`#fff`} radius={'6px'} size={'1rem'} sizemd={'1rem'} weight={'700'}>평가완료</Btn>
                    </div>
                </div>
            }
            {saveModal &&
                <Member setSaveModal={setSaveModal} answers={answers} type={3}/>
            }
            <TemporaryStorage NextSave={NextSave}/>
        </>
    );
}

export default Result_1;
