import styled from "styled-components";

export const Wrap = styled.div`
  width: 100%;
  background: #fff;
  max-width: 1080px;
  margin: 0 auto 40px;
  //padding: 30px 40px;
  border-radius: 8px;
  @media all and (max-width: 767px) {
    width: 90%;
    margin: 0 auto 15px;
  }
`;

export const Wrap2 = styled.div`
  width: 100%;
  background: #fff;
  max-width: 1080px;
  margin: 40px auto;
  //padding: 30px 40px;
  border-radius: 8px;
  @media all and (max-width: 767px) {
    width: 90%;
    margin: 15px auto;
  }
`;

export const Wrap3 = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 767px) {
    width: 90%;
    margin: 15px auto 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: auto;
  @media all and (max-width: 767px) {
    width: 90%;
  }
`;

export const Btn = styled.button`
  font-family: ${(props) => props.family ?? props.family};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#6C6C6C"};
  text-align: ${(props) => props.align || "center"};
  padding: ${(props) => props.padding || "0"};
  border: ${(props) => props.border ?? props.border};
  border-radius: ${(props) => props.radius || "0"};
  background: ${(props) => props.background || "#FFFFFF"};
  margin: ${(props) => props.margin || "0"};
  width: ${(props) => props.width ?? props.width};

  @media (max-width: 768px) {
    font-size: ${(props) => props.sizemd ?? props.sizemd};
    font-weight: ${(props) => props.weightmd ?? props.weightmd};
    color: ${(props) => props.colormd ?? props.colormd};
    text-align: ${(props) => props.alignmd ?? props.alignmd};
    padding: ${(props) => props.paddingmd ?? props.paddingmd};
    border: ${(props) => props.bordermd ?? props.bordermd};
    border-radius: ${(props) => props.radiusmd ?? props.radiusmd};
    margin: ${(props) => props.marginmd ?? props.marginmd};
    width: ${(props) => props.widthmd ?? props.widthmd};
  }
`;

export const NavUl = styled.div`
  background: #fff;
  top: 0;
  right: 0;
  height:100%;
  display:none; 
  transition:0.3s;
  z-index: 9999;
  width: 80%;
  
  @media screen and (max-width: 768px) {
    display:flex;
    flex-direction: column;
    align-items:flex-end;
    position:fixed;
    right:${(props)=> (props.mobile === 1 ? '0' : '-80%')}

}
`

export const Radio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #CBD5E1;
  border-radius: 50%;
  transition: all 0.05s ease-in-out;
  cursor: pointer;
  position: relative;

  /* 내부 점 */
  &:after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* 선택됐을 때 내부 점 스타일 */
  &:checked::after {
    background-color: #7D1825;
  }

  /* 선택되었을 때의 외부 테두리 스타일 */
  &:checked {
    border: 1px solid #7D1825;
  }

`;
