import React, {useEffect, useState} from "react";
import Head from '../../Components/Head';
import {Btn, Container, Wrap2} from "../../Assets/css/Common";
import close from '../../Assets/image/close.png';
import Thumbnail from '../../Assets/image/Thumbnail.png';
import arrarow from '../../Assets/image/arrarow.png';
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../Utils/Api";
import ErrorPage from "../../Components/ErrorPage";
import LinkError from "../../Components/LinkError";

function Home() {
    const navigate = useNavigate();
    const [btn, setBtn] = useState('');
    const [name, setName] = useState('');
    const [state, setState] = useState(0);
    const [part1, setPart1] = useState('');
    const [part2, setPart2] = useState('');
    const [part3, setPart3] = useState('');
    const [hp, setHp] = useState('');
    const params = useParams();
    const [title, setTitle] = useState('');
    const [errormsg, setErrorMsg] = useState('');
    const [errorModal, setErrorModal] = useState('');
    const [errormsg2, setErrorMsg2] = useState('');

    const getData = async () => {
        const res = await API.get(`/users/survey/${params?.surveyNum}/${params?.companyNum}`);
        // console.log('stats',res.data?.status);
        console.log("data",res.data)
        if(res.data?.status === true){
         
            // console.log('stats',res.data.result?.title);
            setTitle(res.data.result?.title);
            setErrorMsg('');
            sessionStorage.setItem('surveyNum', res.data?.survey_id);
            sessionStorage.setItem('companyNum', res?.data?.rater_company_id);
            sessionStorage.setItem('pageStatus', '');
        }else{
            sessionStorage.setItem('pageStatus', res.data?.msg);
            setErrorMsg(res.data?.msg);
        }
    };

    useEffect(() => {
        getData();
    },[btn]);

    const handlePart1Change = (e) => {
        // 입력 값에 숫자 이외의 문자가 포함되지 않도록 정규식을 사용
        setPart1(e.target.value.replace(/\D/g, '').slice(0, 3));
    };

    const handlePart2Change = (e) => {
        setPart2(e.target.value.replace(/\D/g, '').slice(0, 4));
    };

    const handlePart3Change = (e) => {
        setPart3(e.target.value.replace(/\D/g, '').slice(0, 4));
    };

    const onCheck = async (e) => {
       // console.log("!!!!!!!!!!!", e);
        // const hp = part1+part2+part3;
        if (e === 'skip'){
            sessionStorage.setItem('hp', '');
            sessionStorage.setItem('name', '');
            sessionStorage.setItem('load', '');
            sessionStorage.setItem('ArraySearchName',null);
            navigate("/search", {state: {title:title}});
        }else if (e === 'start'){
            if(hp !== '' && name !== '') {
                const res = await API.get(`/users/survey/evaluation-start/${sessionStorage.getItem('surveyNum')}?name=${name}&phone=${hp}&rater_company_id=${sessionStorage.getItem('companyNum')}`);
                // console.log('res2', res);
                if (res.data?.status === true) {
                    sessionStorage.setItem('hp', hp);
                    sessionStorage.setItem('name', name);
                    sessionStorage.setItem('load', '');
                    sessionStorage.setItem('ArraySearchName', null);
                    navigate("/search", {state: {title: title}});
                } else {
                    // console.log(res.data?.msg);
                    setBtn('');
                    // CloseBtn();
                    setState(res.data?.title);
                    setErrorModal(res.data?.msg);
                    sessionStorage.setItem('hp', '');
                    sessionStorage.setItem('name', '');
                    sessionStorage.setItem('load', '');
                    sessionStorage.setItem('ArraySearchName', null);
                }
            }
        }else if (e === 'load'){
            // console.log(name);
            // console.log(hp);
            if (name && hp) {
                const res = await API.get(`/users/survey/onProgress-evaluation/${sessionStorage.getItem('surveyNum')}?name=${name}&phone=${hp}&rater_company_id=${sessionStorage.getItem('companyNum')}`);

               // console.log('데이터체크',res.data);
               // console.log('데이터체크',res);
                if (res.data?.status === true) {
                    setErrorMsg2('');
                    sessionStorage.setItem('hp', hp);
                    sessionStorage.setItem('name', name);
                    sessionStorage.setItem('load', true);
                    sessionStorage.setItem('ArraySearchName',null);
                    sessionStorage.setItem('savemodal','');
                    if(res.data?.last_temporary_storage_item === 'PE'){
                        navigate("/evaluation", {state: {next:1}});
                    }else if(res.data?.last_temporary_storage_item === 'FE'){
                        navigate("/evaluation", {state: {next:2}});
                    }else if(res.data?.last_temporary_storage_item === 'SE'){
                        navigate("/result", {state: {next:1}});
                    }else{
                        navigate("/search", {state: {title:title}});
                    }
                } else {
                    CloseBtn();
                    setErrorMsg2(res.data?.msg);
                    setState(res.data?.title);
                    setErrorModal(true);
                    sessionStorage.setItem('hp', '');
                    sessionStorage.setItem('name', '');
                    sessionStorage.setItem('load', '');
                    sessionStorage.setItem('ArraySearchName',null);
                }
            }else{
                sessionStorage.setItem('hp', '');
                sessionStorage.setItem('name', '');
                sessionStorage.setItem('load', '');
                sessionStorage.setItem('ArraySearchName',null);
            }
        }else {
            console.log('error');
        }
    }

    const CloseBtn = () => {
        setName('');
        setHp('');
        setBtn('');
    }

    const ErrorMsg = (e) => {
        if(e === '이미 설문조사에 참여하셨습니다.' || errormsg2 === '이미 설문조사에 참여하셨습니다.' || errormsg2 === '입력한 정보로 진행한 평가가 없습니다.'){
            setErrorModal(false);
            setName('');
            setHp('');
            setBtn('');
        }else{
            onCheck('load');
        }
        // onCheck('load')
    }

    return (
        <div>
            <Head />
            <Wrap2>
                <div className="py-8 md:py-16 md:flex justify-center">
                    {errormsg === '' ?
                    <div>
                        <img src={Thumbnail} alt={'대표이미지'} className='mx-auto md:mb-10 mb-5 md:w-auto w-[130px]'/>
                        <h1 className="md:text-[2.5rem] text-2xl pb-2 text-[#191F28] font-bold text-center md:mb-10 mb-5">{title}</h1>
                        {/*<p className="font-bold text-[#475569] text-center md:mb-20 mb-10 md:text-base text-sm">본 설문지의 외부 유출 및 공유 금지</p>*/}
                        <div className="flex md:flex-row flex-col-reverse justify-center items-center">
                            <Btn width={'390px'} widthmd={'90%'} padding={'22px 0'} paddingmd={'16px 0'} background={'#F5E9EB'} color={'#7D1825'} radius={'8px'} size={'1.25rem'} sizemd={'0.875rem'} weight={'500'} onClick={() => setBtn(1)}><span className="font-bold">진행중인 평가</span>가 있나요?</Btn>
                            <Btn width={'390px'} widthmd={'90%'} padding={'22px 0'} paddingmd={'16px 0'} background={'#7D1825'} color={'#fff'} radius={'8px'} size={'1.25rem'} sizemd={'0.875rem'} weight={'500'} margin={'0 0 0 20px'}  marginmd={'0 0 10px 0'} onClick={() => setBtn(2)}><span className="font-bold">평가가 처음</span>인가요?</Btn>
                        </div>
                        <div className="mt-6 md:mt-10 text-center">
                            <p className="md:text-base text-xs text-[#4E5968] md:pb-8 pb-5">본 설문의 순서는 아래와 같습니다.<br />(5~10분의 시간이 소요됩니다.)</p>
                            {/* Pc */}
                            <div className="justify-between items-center md:w-4/5 w-11/12 m-auto md:flex hidden">
                                <div>
                                    <p className="text-[#7D1825] font-bold text-xs pb-2.5">STEP 01</p>
                                    <p className="text-[#191F28] font-medium text-base pb-2.5">역량 평가</p>
                                    <p className="text-[#475569] font-medium text-xs">개별 로펌에 대해<br/>역량 평가를 진행합니다.</p>
                                </div>
                                <div>
                                    <img src={arrarow} />
                                </div>
                                <div>
                                    <p className="text-[#7D1825] font-bold text-xs pb-2.5">STEP 02</p>
                                    <p className="text-[#191F28] font-medium text-base pb-2.5">분야별 평가</p>
                                    <p className="text-[#475569] font-medium text-xs">개별 로펌에 대해<br/>분야별 평가를 진행합니다.</p>
                                </div>
                                <div>
                                    <img src={arrarow} />
                                </div>
                                <div>
                                    <p className="text-[#7D1825] font-bold text-xs pb-2.5">STEP 03</p>
                                    <p className="text-[#191F28] font-medium text-base pb-2.5">주관식 평가</p>
                                    <p className="text-[#475569] font-medium text-xs">최고의 변호사/로펌에 대해<br/>주관식 평가를 진행합니다.</p>
                                </div>
                                {/*<div>*/}
                                {/*    <img src={arrarow} />*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <p className="text-[#7D1825] font-bold text-xs pb-2.5">STEP 04</p>*/}
                                {/*    <p className="text-[#191F28] font-medium text-base pb-2.5">평가 완료</p>*/}
                                {/*    <p className="text-[#475569] font-medium text-xs">모든 평가 과정이<br/>완료되었습니다.</p>*/}
                                {/*</div>*/}
                            </div>

                            {/*mo*/}
                            <div className="justify-between items-center md:w-4/5 w-11/12 m-auto md:hidden flex flex-wrap">
                                <div className="w-[48%] mb-[4%] border border-[#CBD5E1] rounded-lg bg-[#F7F9FC] relative flex flex-col items-center justify-center py-[18px]">
                                    <div className="flex justify-center">
                                        <div className="bg-[#4E5968] absolute -top-2 px-[8px] py-[1.5px] rounded-full">
                                            <p className="text-white font-bold text-[0.625rem]">STEP 1</p>
                                        </div>
                                    </div>
                                    <p className="text-[#475569] font-medium text-xs">개별 로펌에 대해<br/><span className="font-bold">역량 평가</span></p>
                                </div>
                                <div className="w-[48%] mb-[4%] border border-[#CBD5E1] rounded-lg bg-[#F7F9FC] relative flex flex-col items-center py-[18px]">
                                    <div className="flex justify-center">
                                        <div className="bg-[#4E5968] absolute -top-2 px-[8px] py-[1.5px] rounded-full">
                                            <p className="text-white font-bold text-[0.625rem]">STEP 2</p>
                                        </div>
                                    </div>
                                    <p className="text-[#475569] font-medium text-xs">개별 로펌에 대해<br/><span className="font-bold">분야별 평가</span></p>
                                </div>
                                <div className="w-[48%] border border-[#CBD5E1] rounded-lg bg-[#F7F9FC] relative flex flex-col items-center py-[18px]">
                                    <div className="flex justify-center">
                                        <div className="bg-[#4E5968] absolute -top-2 px-[8px] py-[1.5px] rounded-full">
                                            <p className="text-white font-bold text-[0.625rem]">STEP 3</p>
                                        </div>
                                    </div>
                                    <p className="text-[#475569] font-medium text-xs">최고의 변호사/로펌을<br/>선정하는 <span className="font-bold">주관식 평가</span></p>
                                </div>
                                <div className="w-[48%] border border-[#CBD5E1] rounded-lg bg-[#F7F9FC] relative flex flex-col items-center py-[28px]">
                                    <div className="flex justify-center">
                                        <div className="bg-[#4E5968] absolute -top-2 px-[8px] py-[1.5px] rounded-full">
                                            <p className="text-white font-bold text-[0.625rem]">STEP 4</p>
                                        </div>
                                    </div>
                                    <p className="text-[#475569] font-bold text-xs">로펌평가 완료</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                        <LinkError />
                    }
                </div>
            </Wrap2>
            <div className="w-full text-center">
                <p className="md:text-sm text-[10px] text-[#4E5968]">Copyright ⓒ 2024 law firm rating. All Rights Reserved</p>
            </div>

            {btn &&
            <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                    <div className="flex justify-end items-center pb-1" >
                        <img src={close} alt='취소' className="cursor-pointer md:w-auto w-5 md:h-auto h-5" onClick={CloseBtn}/>
                    </div>
                    <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">{btn === 2 ? '평가 시작하기' : '진행중인 평가 불러오기'}</p>
                    <p className="text-sm md:text-base text-slate-600 tracking-normal md:tracking-tighter text-center">{btn === 2 ? <>임시저장을 하시려면 이름과 휴대폰번호를<br/>입력 후 평가를 시작해 주세요.</> : <>평가 시작 시 작성했던 이름과 <br />휴대폰번호를 입력해주세요.</>}</p>
                    <p className="text-sm md:text-base text-[#7D1825] tracking-normal md:tracking-tighter text-center">이름과 휴대전화 번호는 임시저장 기능을 <br className="md:hidden block"/>위해서만 <br className="md:block hidden"/>활용되며, 수집·저장되지 않습니다.</p>
                    <form className="mt-4 md:mt-7" onSubmit={onCheck}>
                        <input type={"text"} placeholder={"이름"} value={name} onChange={(e) => setName(e.target.value)} className="border-b border-b-slate-300 w-full mb-6 text-base pb-[6px]"/>
                        <div className="border-b border-b-slate-300 w-full mb-6 md:mb-8 pb-[6px]">
                            <input
                                type="text"
                                value={hp}
                                onChange={(e) => setHp(e.target.value.replace(/\D/g, '').slice(0, 11))}
                                maxLength="11"
                                placeholder="-빼고 휴대폰번호를 입력해주세요."
                                className="w-full text-base"
                            />
                        </div>
                        {btn === 2 ?
                            <div className="flex justify-between items-center">
                                <Btn onClick={()=>onCheck('skip')} width={'49%'} padding={'10px 0'} background={'#fff'} border={'1px solid #CBD5E1'} color={'#475569'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} type={"button"}>건너뛰기</Btn>
                                <Btn onClick={()=>onCheck('start')} width={'49%'} padding={'10px 0'} background={name && hp ? '#7D1825' : '#CBD5E1'} border={name && hp ? '1px solid #7D1825' : '1px solid #CBD5E1'} color={name && hp ? '#fff' : '#E6ECF2'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} type={"button"}>시작하기</Btn>
                            </div>
                        :
                            <Btn onClick={()=>onCheck('load')} width={'100%'} padding={'10px 0'} background={name && hp ? '#7D1825' : '#CBD5E1'} border={name && hp ? '1px solid #7D1825' : '1px solid #CBD5E1'} color={name && hp ? '#fff' : '#E6ECF2'} radius={'6px'} size={'1rem'} weight={'700'} type={"button"}>확인</Btn>
                        }
                    </form>
                </div>
            </div>
            }
            {errorModal &&
                <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                    <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                    <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                        <div className="flex justify-end items-center pb-1" >
                            <img src={close} alt='취소' className="cursor-pointer md:w-auto w-5 md:h-auto h-5" onClick={() => setErrorModal(false)}/>
                        </div>
                        <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">진행중인 평가 {state === 2 ? '완료' : state === 1 ? '있음' : '없음'}</p>
                        <p className="text-sm md:text-base text-slate-600 tracking-normal md:tracking-tighter text-center md:pb-10 pb-5"  dangerouslySetInnerHTML={{ __html: errorModal === true ? errormsg2 : errorModal.replace(/\n/g, '<br>') }}></p>
                        <Btn onClick={()=> ErrorMsg(errorModal)} width={'100%'} padding={'10px 0'} background={'#7D1825'} border={'1px solid #7D1825'} color={'#fff'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} type={"button"}>확인</Btn>
                    </div>
                </div>
            }
        </div>
    );
}

export default Home;
