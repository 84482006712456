import React from 'react';
import ReactDOM from 'react-dom/client';
import "tailwindcss/tailwind.css";
import { createGlobalStyle } from "styled-components";
import App from './App';
import reportWebVitals from './reportWebVitals';

const GlobalStyle = createGlobalStyle`
  /*===== FONTS =====*/
  /* Pretendard */
  @font-face {
    font-family: 'Pretendard';
    font-weight: 900;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff') format('woff');
  }
  @font-face {
    font-family: 'Pretendard';
    font-weight: 800;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
  }
  @font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'Pretendard';
    font-weight: 600;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
  }
  @font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'Pretendard';
    font-weight: 300;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff') format('woff');
  }
  @font-face {
    font-family: 'Pretendard';
    font-weight: 200;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff') format('woff');
  }
  @font-face {
    font-family: 'Pretendard';
    font-weight: 100;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff') format('woff');
  }
  /* Binggrae */
  @font-face {
    font-family: 'Binggrae';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/Binggrae.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  /*===== INITIALIZATION =====*/
  // html{font-size:10px;}
  body{font-family:'Pretendard'; font-size:1rem; color:#000; font-weight:400; background: #F8FAFC; touch-action: pan-y;}

  html, body, h1, h2, h3, h4, h5, h6, p, form, fieldset, img, pre{margin:0; padding:0; border:0; font-family:'Pretendard';}
  ol, ul, dl, dt, dd, button, input, select, textarea{margin:0; padding:0;}
  table{border-spacing:0;}
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section{display:block;}
  legend{position:absolute; margin:0; padding:0; font-size:0; line-height:0; text-indent:-9999em; overflow:hidden;}
  hr{display:none;}
  pre{font:inherit; color:inherit; white-space:pre-wrap;}
  ol, ul, dl, dt, dd{list-style:none;}
  label, input, button, select, img{vertical-align:middle;}
  button, input, select, textarea, label{font-family:'Pretendard'; margin:0;}
  button, input[type="submit"]{cursor:pointer;}
  input[type="text"], input[type="password"], textarea{transition:all 0.3s ease-in-out; -webkit-transition:all 0.3s ease-in-out; -moz-transition:all 0.3s ease-in-out; -ms-transition:all 0.3s ease-in-out; -o-transition:all 0.3s ease-in-out; outline:none;}
  input[type=text]:focus, input[type=password]:focus, textarea:focus, select:focus, .btn:focus{box-shadow:none; outline:none;}
  input::-webkit-input-placeholder, input:-ms-input-placeholder{color:#c0c0c0;}
  textarea::-webkit-input-placeholder, textarea:-ms-input-placeholder{color:#c0c0c0;}
  //input:read-only{cursor:text;}
  button{display:block; border:0; background-color:transparent; appearance:none; -moz-appearance:none; -webkit-appearance:none; border-radius:0; -webkit-border-radius:0; -moz-border-radius:0;}
  a{color:inherit; text-decoration:none;}
  button, a{cursor:pointer;}
  a:hover{/*color:inherit;*/ text-decoration:none;}
  button, a, button:hover, a:hover{transition:all 0.1s linear; -webkit-transition:all 0.1s linear; -moz-transition:all 0.1s linear; -ms-transition:all 0.1s linear; -o-transition:all 0.1s linear;}
  p{word-break:break-all;}
  img{display:block; max-width:100%; height:auto;}
  *, :after, :before{box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box;}
  *:disabled{cursor:default;}

  //#root{overflow:hidden;}
  .sub_txt{
    color: #4E5968;font-size: 1rem;
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
`;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
