import React, {useEffect, useState, useRef } from "react";
import Head from '../../Components/Head';
import {Btn, Container, Wrap2} from "../../Assets/css/Common";
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import { API } from "../../Utils/Api";
import search from "../../Assets/image/search.png";
import Thumbnail from '../../Assets/image/Thumbnail.png';
import { BrowserView, MobileView } from 'react-device-detect';
import off from "../../Assets/image/checkoff.png";
import on from "../../Assets/image/checkon2.png";
import History from "../../Components/History";
import close from '../../Assets/image/close.png';
import temporaryStorage from "../../Components/TemporaryStorage";
import LinkError from "../../Components/LinkError";
import {createBrowserHistory} from "history";
const history = createBrowserHistory();
function Search() {
    const navigate = useNavigate();
    const location = useLocation();
    const state = { ...location.state };
    const inputRef2 = useRef(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [historyData, setHistoryData] = useState(JSON.parse(sessionStorage.getItem('ArraySearchName')) || []);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchId, setSearchId] = useState('');
    const [data, setData] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [searchCheck, setSearchCheck] = useState(false);
    const [errorModal, setErrorModal] = useState('');
    const [errormsg2, setErrorMsg2] = useState('');

    useEffect(() => {
        const handleNavigation = (action) => {
            if (action === "POP") {
                if(window.confirm('페이지를 벗어나면 작성된 내용은 저장되지 않습니다.\n이 페이지에서 벗어나시겠습니까?')){
                    navigate('/');
                } else {
                    navigate(location.pathname, { replace: false, state: { title: location.state.title }});
                }
            }
        };

        const unlisten = history.listen(({ action }) => handleNavigation(action));

        return () => unlisten();

    }, [navigate, history]);

    const getData = async () => {
        const res = await API.get('/users/survey/law-firm');
        if(res.data.status === true) {
            setData(res.data?.result);
            setSearchResults(res.data?.result);
        }else{
            console.log('error')
        }
    };

    const getData2 = async () => {
        const res = await API.get(`/users/survey/law-firm-check/${sessionStorage.getItem("surveyNum")}?phone=${sessionStorage.getItem("hp")}&name=${sessionStorage.getItem("name")}&company_target_id=${searchId}&rater_company_id=${sessionStorage.getItem("companyNum")}`);
        // console.log("res", res.data);
        if (res.data.status === true) {
            navigate('/evaluation');
        }else{
            setErrorModal(res.data?.msg);
        }
    };

    useEffect(() => {
        getData();
    },[])

    const FocusClick = () => {
        setSearchTerm('');
        setSearchId('');
        sessionStorage.setItem('searchid', '');
        sessionStorage.setItem('searchname', '');
        setHoveredIndex('');
        setIsFocused(true);
    }

    useEffect(() => {
        // isFocused 상태가 true로 변경되면 inputRef2에 포커스를 줍니다.
        if (isFocused && inputRef2.current) {
            inputRef2.current.focus();
        }
    }, [isFocused]);

    const handleSearch = (query) => {
        setSearchTerm(query);
        // console.log('query',query);
        const filtered = data.filter(item =>
            item.law_firm_name.includes(query)
        );
        setSearchResults(filtered);
        // setSearchTerm(query);
        // // 쿼리를 소문자로 변환
        // const lowerCaseQuery = query.toLowerCase();
        //
        // // 데이터를 필터링하여 각 항목의 시작 부분이 쿼리와 일치하는지 확인
        // const filtered = data.filter(item =>
        //     item.law_firm_name.toLowerCase().startsWith(lowerCaseQuery)
        // );

        setSearchResults(filtered);
    };

    const handleSelect = (value,id) => {
        setSearchTerm(value);
        setSearchId(id);
        setSearchCheck(true);
        setIsFocused(false); // 리스트를 닫음
    };

    // 내가 평가했던로펌 또 못하게 막기

    const onNext = () => {
        // console.log(searchId);
        // console.log(searchTerm);
        sessionStorage.setItem('searchid', searchId);
        sessionStorage.setItem('searchname', searchTerm);
        // const res = await API.get(`/users/survey/onProgress-evaluation/${SurveyNum?.[0]}?name=${name?.[0]}&phone=${hp?.[0]}&rater_company_id=${CompanyNum?.[0]}`);
        if(sessionStorage.getItem("hp") && sessionStorage.getItem("name")){
            getData2();
        }else{
            const firmExists = historyData.some(firm => firm.law_firm_name === searchTerm);
            if(firmExists === true){
                setErrorModal('이미 평가한 로펌입니다.');
            }else{
                navigate('/evaluation');
            }
        }

     //   navigate('/evaluation');
    }

    const SearchReset = (e) => {
        setIsFocused(false)
        setSearchTerm('');
        setSearchCheck(false);
    }

    return (
        <>
        {sessionStorage.getItem('pageStatus') === '' ?
        <div className="h-[calc(100vh+5rem)] md:h-[calc(100vh+10rem)]">
            <Head />
            <Wrap2>
                <div className="md:py-[70px] py-[30px]">
                    <div className="md:flex justify-center">
                        <div>
                            <img src={Thumbnail} alt={'대표이미지'} className='mx-auto md:mb-10 mb-5 md:w-auto w-[130px]'/>
                            <h1 className="md:text-[2.5rem] text-2xl text-[#191F28] font-bold text-center mb-5">{state?.title}</h1>
                            {/*<p className="font-bold text-[#475569] text-center md:mb-10 mb-10 md:text-base text-sm">본 설문지의 외부 유출 및 공유 금지</p>*/}
                            <p className="font-medium text-[#4E5968] text-sm md:text-xl text-center">평가대상은 법무부에 등록된 <br className="block md:hidden" /><span className="text-[#7D1825] font-bold">1,500여 개의 전체 로펌</span>입니다.<br className="block md:hidden" />(김앤장 법률사무소 포함)</p>
                            <p className="font-medium text-[#4E5968] text-sm md:text-xl text-center mb-5">평가하고자 하는 로펌을 검색해주세요.</p>
                            <p className="font-medium text-[#999999] text-xs md:text-base text-center pb-5 md:pb-10">ⓘ 1단계 - 2단계 개별로펌 평가는 <br className="md:hidden visible"/>여러 개의 로펌을 평가할 수 있습니다.</p>
                            {/*<p className="font-medium text-slate-900 text-base md:text-lg text-center md:mb-10 mb-10"><b>로펌명은 한글로만 검색해주세요. <br />예시&#41; LKB &#8594; 엘케이비 / YK &#8594; 와이케이</b></p>*/}
                        </div>
                    </div>
                    {isFocused ? (
                        <div className="w-full flex flex-col items-center">
                            <div className="w-[90%] md:w-[700px] flex justify-between items-center border-2 border-[#7D1825] rounded-tl-[22.5px] rounded-tr-[22.5px] md:py-5 md:px-10 py-2.5 px-5">
                                <input
                                    type="text"
                                    placeholder={`로펌명을 한글로 입력해 주세요. (ex. LKB → 엘케이비, YK → 와이케이)`}
                                    ref={inputRef2}
                                    value={searchTerm}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onFocus={() => setIsFocused(true)}
                                    className="w-full text-xs md:text-base font-bold text-slate-900"
                                />
                                <img onClick={SearchReset} src={search} alt={"검색"} className="w-[16px] md:w-[22px] cursor-pointer"/>
                            </div>
                            <div className="shadow-xl w-[90%] md:w-[700px] bg-white border-x border-b border-[#CBD5E1] rounded-bl-[22.5px] rounded-br-[22.5px] h-[160px] md:h-[260px] overflow-x-auto scrollbar-hide">
                                <ul>
                                    {searchTerm && searchResults.map((result, index) => (
                                        <li key={index} onClick={() => handleSelect(result.law_firm_name,result.id)} onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)} className="flex justify-between items-center px-5 md:px-[40px] py-2.5 md:py-5 hover:bg-[#F5E9EB]">
                                            <p className="text-xs md:text-base font-bold">{result.law_firm_name}</p>
                                            <img src={hoveredIndex === index ? on : off} alt={'check'} style={{width: 20, height: 20, objectFit: 'none'}}/>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ) : (
                    <>
                        <div className="w-full flex justify-center">
                            <div className="w-[90%] md:w-[700px] flex justify-between items-center border-2 border-[#7D1825] rounded-[42px] md:py-5 md:px-10 py-2.5 px-5">
                                <input
                                    type="text"
                                    placeholder={`로펌명을 한글로 입력해 주세요. (ex. LKB → 엘케이비, YK → 와이케이)`}
                                    value={searchTerm}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onFocus={FocusClick}
                                    className="w-full text-xs md:text-base font-normal md:font-bold text-slate-900"
                                />
                                <img src={search} alt={"검색"} className="w-[16px] md:w-[22px]"/>
                            </div>
                        </div>
                        {searchCheck &&
                            <div className="flex justify-center md:mt-10 mt-10">
                                <div>
                                    <p className="font-medium text-[#4E5968] text-sm md:text-xl text-center pb-5 md:pb-10"><span className="text-[#7D1825] font-bold">{searchTerm}</span>에 관해 <br className="block md:hidden" /> 로펌 평가 설문을 시작하시겠습니까?</p>
                                    <Btn width={'200px'} widthmd={'100%'} padding={'14px 0'} paddingmd={'14px 0'} background={'#7D1825'} color={'#fff'} radius={'6px'} size={'1rem'} sizemd={'0.825rem'} weight={'700'} margin={'auto'} onClick={onNext}>평가 시작</Btn>
                                </div>
                            </div>
                        }
                    </>
                )}

                    <div className="mt-10 flex items-center justify-center">
                        <p className="font-medium text-xs md:text-base text-[#4E5968] pr-2.5">로펌 이름이 생각나지 않는다면?</p>
                        <Btn width={'175px'} widthmd={'99px'} padding={'10px 0'} background={'#7D1825'} color={'#fff'} radius={'50px'} size={'1rem'} sizemd={'11px'} weight={'700'} onClick={()=>{window.open('https://stib.ee/35iA')}}>로펌목록 보러가기</Btn>
                    </div>
                </div>
                <History />
            </Wrap2>
            <div className="w-full text-center">
                <p className="md:text-sm text-[10px] text-[#4E5968]">Copyright ⓒ 2024 law firm rating. All Rights Reserved</p>
            </div>
             {errorModal &&
                <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                    <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                    <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                        <div className="flex justify-end items-center pb-1" >
                            <img src={close} alt='취소' onClick={() => setErrorModal(false)} className="cursor-pointer md:w-auto w-5 md:h-auto h-5"/>
                        </div>
                        <p className="text-base md:text-xl text-[#7D1825] font-bold text-center md:pb-4 pb-2.5">{searchTerm}</p>
                        <p className="text-sm md:text-base text-slate-600 tracking-normal md:tracking-tighter text-center md:pb-10 pb-5" dangerouslySetInnerHTML={{ __html: errorModal === true ? errormsg2 : errorModal.replace(/\n/g, '<br>') }}></p>
                        <div className="flex items-center justify-between mb-8">
                            <Btn onClick={()=>setErrorModal(false)} background={'#7D1825'} border={'1px solid #7D1825'} color={'#fff'} width={'100%'} padding={'14px 0'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'}>확인</Btn>
                        </div>
                    </div>
                </div>
            }
        </div>
            :
            <LinkError />
        }
        </>
    );
}

export default Search;

