import React, {useEffect, useState} from "react";
import Head from '../../Components/Head';
import {Wrap} from "../../Assets/css/Common";
import Thumbnail from '../../Assets/image/Thumbnail.png';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { createBrowserHistory } from "history";
import Flowchart from "../../Components/Flowchart";
import LinkError from "../../Components/LinkError";

const history = createBrowserHistory();
function Complete() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleNavigation = (action) => {
            if (action === "POP") {
                if(window.confirm('페이지를 벗어나면 작성된 내용은 저장되지 않습니다.\n이 페이지에서 벗어나시겠습니까?')){
                    navigate('/');
                } else {
                    navigate(location.pathname, { replace: false});
                }
            }
        };

        const unlisten = history.listen(({ action }) => handleNavigation(action));

        return () => unlisten();
    }, [navigate, history]);

    useEffect(() => {
        sessionStorage.setItem('hp', '');
        sessionStorage.setItem('name', '');
        sessionStorage.setItem('load', '');
        sessionStorage.setItem('ArraySearchName',null);
        sessionStorage.setItem('searchid', '');
        sessionStorage.setItem('searchname', '');
        sessionStorage.setItem('surveyNum','0');
        sessionStorage.setItem('companyNum','0');
        sessionStorage.setItem('gubun', 1);
    //     const listenBackEvent = () => {
    //         // 뒤로가기 할 때 수행할 동작을 적는다
    //        navigate('/complete');
    //   };

    //     history.listen(({ action }) => {
    //       if (action === "POP") {
    //         listenBackEvent();
    //       }
    //     });

    },[])


    // useEffect(() => {
    //     const handleBackButtonClick = () => {
    //         // 원하는 경로로 이동
    //        navigate('/evaluation');
    //     };
    //
    //     // popstate 이벤트 리스너 추가
    //     window.addEventListener('popstate', handleBackButtonClick);
    //
    //     // 컴포넌트 unmount 시 이벤트 리스너 제거
    //     return () => {
    //         window.removeEventListener('popstate', handleBackButtonClick);
    //     };
    // }, [navigate]);

    return (
        <>
        {sessionStorage.getItem('pageStatus') === '' ?
        <div>
            <Head />
            <Flowchart check={4} />
            <Wrap>
                <div className="py-8 md:py-16 md:flex justify-center">
                    <div>
                        <img src={Thumbnail} alt={'대표이미지'} className='mx-auto md:mb-10 mb-5 md:w-auto w-[130px]'/>
                        <p className="font-medium text-[#4E5968] text-lg md:text-3xl text-center pb-0 md:pb-4">소중한 시간을 내어 설문조사에 <br className="md:hidden visible"/>참여해 주셔서 감사합니다.</p>
                        <p className="font-bold text-[#191F28] text-lg md:text-3xl text-center">귀하의 소중한 의견은 <br className="md:hidden visible"/>저희에게 큰 도움이 됩니다.</p>
                        {/*<h1 className="md:text-[2.25rem] text-2xl pb-2 text-[#7D1825] font-bold md:pb-4 text-center md:mb-20 mb-10">설문에 참여해 주셔서 감사합니다.</h1>*/}
                    </div>
                </div>
            </Wrap>
            <div className="w-full text-center pb-4">
                <p className="md:text-sm text-[10px] text-[#4E5968]">Copyright ⓒ 2024 law firm rating. All Rights Reserved</p>
            </div>
        </div>
            :
            <LinkError />
        }
        </>
    );
}

export default Complete;
