import React, {useEffect, useState} from "react";
import error from '../Assets/image/404page.png';
import error2 from '../Assets/image/404page_mo.png';

function ErrorPage() {

    return (
        <div className="flex justify-center items-center h-screen">
            {/*<h1 className="md:text-[2rem] text-xl pb-2 text-[#475569] font-bold md:pb-4 text-center md:mb-20 mb-10">유효한 링크가 아닙니다.</h1>*/}
            <img src={error} alt={'404'} className="md:block hidden"/>
            <img src={error2} alt={'404'} className="md:hidden block"/>
        </div>
    );
}

export default ErrorPage;
