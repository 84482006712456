import React, {useEffect, useState } from "react";
import {Btn} from "../../Assets/css/Common";
import off from "../../Assets/image/checkoff.png";
import on from "../../Assets/image/checkon.png";
import {Evaluation2} from "./EvaluationData";
import { API } from "../../Utils/Api";
import close from "../../Assets/image/close.png";
import Member from "../../Components/Member";
import TemporaryStorage from "../../Components/TemporaryStorage";
import {useLocation, useNavigate} from "react-router-dom";
import {createBrowserHistory} from "history";
const history = createBrowserHistory();

function Evaluation_2(props) {
    const {hp, setHp, name, setName, searchname, SurveyNum, CompanyNum, setSearchName, setSearchId, answers, setAnswers, setSubmitModal, NextSubmit, setBefore} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [checkedADVICE, setCheckedADVICE] = useState({});
    const [checkedLITIGATION, setCheckedLITIGATION] = useState({});
    const [dataADVICE, setDataADVICE] = useState([]);
    const [dataLITIGATION, setDataLITIGATION] = useState([]);
    const [data, setData] = useState([]);
    const [item, setItem] = useState([]);
    const [pageStatus,setPageStatus] = useState(true);
    const [errorModal, setErrorModal] = useState(false);
    const [saveModal,setSaveModal] = useState(false);
    const [gubun, setGubun] = useState(sessionStorage.getItem('gubun') || 1);

    useEffect(() => {
        const handleNavigation = (action) => {
            if (action === "POP") {
                if(window.confirm('페이지를 벗어나면 작성된 내용은 저장되지 않습니다.\n이 페이지에서 벗어나시겠습니까?')){
                    navigate('/');
                } else {
                    props.setNext(2);
                    navigate(location.pathname, {replace: false});
                }
            }
        };

        const unlisten = history.listen(({ action }) => handleNavigation(action));

        return () => unlisten();
    }, [navigate, history]);

    useEffect(() => {
        setHp(sessionStorage.getItem('hp'));
        setName(sessionStorage.getItem('name'));
    },[saveModal]);

    const ADVICECheckboxChange = (key,name) => {

        setCheckedADVICE((prevCheckedItems) => {
            const updatedItems = { ...prevCheckedItems };

            // 이미 체크된 상태인 경우 해당 키를 삭제
            if (updatedItems[key]) {
                delete updatedItems[key];
                setAnswers(prevAnswers => {
                    const updatedAnswers = { ...prevAnswers };
                    delete updatedAnswers[key];
                    return updatedAnswers;
                });
            } else {
                // 체크되지 않은 경우 추가
                updatedItems[key] = name;
            }

            return updatedItems;
        });
    };

    const LITIGATIONCheckboxChange = (key,name) => {
        setCheckedLITIGATION((prevCheckedItems) => {
            const updatedItems = { ...prevCheckedItems };

            // 이미 체크된 상태인 경우 해당 키를 삭제
            if (updatedItems[key]) {
                delete updatedItems[key];
                setAnswers(prevAnswers => {
                    const updatedAnswers = { ...prevAnswers };
                    delete updatedAnswers[key];
                    return updatedAnswers;
                });
            } else {
                // 체크되지 않은 경우 추가
                updatedItems[key] = name;
            }

            return updatedItems;
        });
    };

    const getData = async () => {
        const res = await API.get(`/users/survey-question/${SurveyNum}`);
        // console.log('리스트', res);
        if(res.data.status === true){
            setData(res.data?.result?.fe_questions);
            setPageStatus(res.data.status);
        }else{
            setPageStatus(res.data.status);
        }
    };
// console.log(data);
    const getData2 = async () => {
        const res = await API.get(`/users/survey/onProgress-evaluation/${SurveyNum}?name=${name}&phone=${hp}&rater_company_id=${CompanyNum}`);
        // console.log('임시저장불러오기', res);
        // console.log('gubun', gubun);
        if (res.data?.result?.length !== 0) {
            if (res.data?.result?.[0]?.gubun === gubun) {
                sessionStorage.setItem('gubun', 0);
                setGubun(0);
                // console.log('id',sessionStorage.getItem('searchname'));
                if (sessionStorage.getItem('searchid') === null) {
                    setSearchName(res.data?.result?.[0]?.company_target.law_firm_name);
                    setSearchId(res.data?.result?.[0]?.company_target_id);
                    setItem(res.data?.result?.[0]?.temporary_storage_histories);
                    const initialAnswers = {};
                    res.data?.result?.[0]?.temporary_storage_histories?.forEach(item2 => {
                        if (item2.fe_question_id != null) {
                            initialAnswers[item2.fe_question_id] = item2.point;
                        }
                    });

                    // console.log('initialAnswers',initialAnswers)
                    setAnswers(initialAnswers);
                }
            }
        }
        // console.log(res.data?.result?.pe_questions);
    };

    useEffect(() => {
        getData2();
        getData();
    },[]);

    // console.log(data);
    useEffect(() => {
        if (item && item.length > 0) {
            const adviceData = {};
            const litigationData = {};

            item?.forEach(item2 => {
                const matchedItem = data?.find(d => {
                        // console.log('w',d);
                        return parseInt(d.id) === parseInt(item2.fe_question_id)
                    }
                );
                if (matchedItem) {
                    // matchedItem 로직
                    if (matchedItem.gubun === 'ADVICE') {
                        adviceData[matchedItem.id] = matchedItem.item;
                    } else if (matchedItem.gubun === 'LITIGATION') {
                        litigationData[matchedItem.id] = matchedItem.item;
                    }
                }
            });
            setCheckedADVICE(adviceData);
            setCheckedLITIGATION(litigationData);
        }
    },[data, item])
    // console.log('checkedADVICE',checkedADVICE);
    // console.log('2',answers);
    useEffect(() => {
        EvaluationCheck();
    },[checkedADVICE,checkedLITIGATION])
    const EvaluationCheck = () => {
        const yourArray = Object.entries(checkedADVICE).map(([id, item]) => ({ id: parseInt(id), item }));
        const yourArray2 = Object.entries(checkedLITIGATION).map(([id, item]) => ({ id: parseInt(id), item }));
        // console.log(yourArray);
        setDataADVICE(yourArray);
        setDataLITIGATION(yourArray2);
        // setOpen(true);
    }
    // console.log('d',dataADVICE);
    // console.log('l',dataLITIGATION);
    const handleAnswerChange = (questionId, value) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: value,
        }));
    };

    const isAllAnswersSelected = () => {
        const combinedArray = dataADVICE.concat(dataLITIGATION);

        if (combinedArray.length === 0) {
            return false;
        }
        // 각 질문에 대한 답변이 있는지 확인
        return combinedArray.every((question) => {
            return answers[question.id] !== undefined;
        });
    };

    const OnSubmit = () => {
        if(isAllAnswersSelected()){
            setSubmitModal(true);
        }else{
            setErrorModal(true);
        }
    };

    const NextSave = () => {
        // console.log(hp)
        if(hp !== '' && name !== ''){
            NextSubmit('TemporaryStorage');
        }else{
            setSaveModal(true);
        }
    }

    const Before = () => {
        props.setNext(1);
        setBefore(1);
    }
    // console.log('answers',answers);
    return (
        <>
            {pageStatus === true &&
                <div className="md:p-10 p-4">
                    <p className="text-[1.125rem] md:text-xl text-[#7D1825] font-bold pb-2.5 md:pb-2.5">{searchname}</p>
                    <div className="pb-5 md:pb-10 border-b border-b-slate-300 mb-5 md:mb-10">
                        <p className="text-sm md:text-base text-slate-900 font-normal">평가할 분야를 선택해 주세요.</p>
                    </div>
                    <div>
                        <p className="text-sm md:text-base font-bold text-slate-900 pb-2">자문</p>
                        <div className="flex items-center flex-wrap mb-5 md:mb-10">
                            {data.map((list, key) => {
                                if (list.gubun === 'ADVICE') {
                                    return (
                                        <label
                                            htmlFor={key+1}
                                            className={`cursor-pointer flex items-center py-1 md:py-2 px-2 md:px-4 border rounded-3xl mr-2 mb-[6px] md:mb-[12px]  ${checkedADVICE[list.id] ? 'border-[#7D1825] bg-[#F5E9EB]' : 'border-[#CBD5E1] bg-[#F8FAFC]'}`}
                                            key={key}
                                        >
                                            <p className={`cursor-pointer text-sm md:text-base pr-3 ${checkedADVICE[list.id] ? 'text-[#191F28]' : 'text-[#475569]'}`}>{list.item}</p>
                                            <img src={checkedADVICE[list.id] ? on : off} alt={'체크전'} className="cursor-pointer"/>
                                            <input
                                                type="checkbox"
                                                checked={checkedADVICE[list.id] || false}
                                                onChange={() => ADVICECheckboxChange(list.id,list.item)}
                                                id={key+1}
                                                className="hidden"
                                            />
                                        </label>
                                    );
                                }
                            })}
                        </div>
                        <p className="text-sm md:text-base font-bold text-slate-900 pb-2">송무</p>
                        <div className={`flex items-center flex-wrap ${dataADVICE.length !== 0 || dataLITIGATION.length !== 0 ? 'mb-12' : 'mb-5'}`}>
                            {data.map((list, key) => {
                                if (list.gubun === 'LITIGATION') {
                                    return (
                                        <label
                                            htmlFor={key+1}
                                            className={`cursor-pointer flex items-center py-1 md:py-2 px-2 md:px-4 border rounded-3xl mr-2 mb-[6px] md:mb-[12px] ${checkedLITIGATION[list.id] ? 'border-[#7D1825] bg-[#F5E9EB]' : 'border-[#CBD5E1] bg-[#F8FAFC]'}`}
                                            key={key}
                                        >
                                            <p className={`cursor-pointer text-sm md:text-base pr-3 ${checkedLITIGATION[list.id] ? 'text-[#191F28]' : 'text-[#475569]'}`}>{list.item}</p>
                                            <img src={checkedLITIGATION[list.id] ? on : off} alt={'체크전'} className="cursor-pointer"/>
                                            <input
                                                type="checkbox"
                                                checked={checkedLITIGATION[list.id] || false}
                                                onChange={() => LITIGATIONCheckboxChange(list.id,list.item)}
                                                id={key+1}
                                                className="hidden"
                                            />
                                        </label>
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <div>
                        {dataADVICE.length !== 0 || dataLITIGATION.length !== 0 ?
                            <div>
                                <div className="flex md:flex-row flex-col md:items-center md:justify-between pb-5 md:pb-10 border-b border-b-slate-300 mb-5 md:mb-10">
                                    <p className="text-sm md:text-base text-[#4E5968] font-normal pb-4 md:pb-0">선택한 분야별 전문성을 5점 척도로 평가해 주세요.<br />
                                        모든 분야를 필수적으로 평가하지 않아도 됩니다.</p>
                                    <p className="text-xs md:text-base text-[#4E5968]">
                                        <span className="font-bold">5</span>: 탁월<span className="px-2">|</span>
                                        <span className="font-bold">4</span>: 우수<span className="px-2">|</span>
                                        <span className="font-bold">3</span>: 보통<span className="px-2">|</span>
                                        <span className="font-bold">2</span>: 미흡<span className="px-2">|</span>
                                        <span className="font-bold">1</span>: 불량</p>
                                </div>
                                {dataADVICE.map((list, key) => {
                                    // console.log(item);
                                    const correspondingAnswer = item?.find(item2 => item2.fe_question_id === list?.id);
                                    // console.log('correspondingAnswer.point',correspondingAnswer.point);
                                    return (
                                        <div className="pb-10" key={key}>
                                            <p className="font-normal text-sm md:text-xl text-slate-900 pb-2"><span className="font-bold">자문</span> - {list.item}</p>
                                            <Evaluation2 name={list.id} onAnswerChange={handleAnswerChange} point={correspondingAnswer ? correspondingAnswer.point : null}/>
                                        </div>
                                    );
                                })}
                                {dataLITIGATION.map((list, key) => {
                                    // console.log(item);
                                    const correspondingAnswer = item?.find(item2 => item2.fe_question_id === list?.id);
                                    // console.log(correspondingAnswer);
                                    return (
                                        <div className="pb-10" key={key}>
                                            <p className="font-normal text-sm md:text-xl text-slate-900 pb-2"><span className="font-bold">송무</span> - {list.item}</p>
                                            <Evaluation2 name={list.id} onAnswerChange={handleAnswerChange} point={correspondingAnswer ? correspondingAnswer.point : null}/>
                                        </div>
                                    );
                                })}
                            </div>
                            : '' }
                        <div className="flex items-center justify-center">
                            <Btn onClick={Before} background={'#fff'} border={'1px solid #CBD5E1'} color={'#475569'} width={'200px'} widthmd={'100%'} padding={'14px 0'} radius={'6px'} size={'1rem'} sizemd={'1rem'} weight={'700'} margin={'0 16px 0 0'}>이전</Btn>
                            <Btn onClick={OnSubmit} background={`${isAllAnswersSelected() ? '#0F172A' : '#CBD5E1'}`} color={`${isAllAnswersSelected() ? '#fff' : '#E6ECF2'}`} width={'200px'} widthmd={'100%'} padding={'14px 0'} radius={'6px'} size={'1rem'} sizemd={'1rem'} weight={'700'}>제출</Btn>
                        </div>
                    </div>
                    {/*}*/}
                </div>
            }
            {errorModal &&
                <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                    <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                    <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                        <div className="flex justify-end items-center pb-1" >
                            <img src={close} alt='취소' onClick={() => setErrorModal(false)} className="cursor-pointer md:w-auto w-5 md:h-auto h-5"/>
                        </div>
                        <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">미응답 항목 안내</p>
                        <p className="pb-6 text-slate-900 text-sm md:text-base font-medium text-center tracking-normal md:tracking-tighter">선택하신 모든 문항에<br />응답하지 않으셨습니다.</p>
                        <div className="flex items-center justify-between">
                            <Btn onClick={() => setErrorModal(false)} background={'#7D1825'} border={'1px solid #7D1825'} color={'#fff'} width={'100%'} padding={'14px 0'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'}>확인</Btn>
                        </div>
                    </div>
                </div>
            }
            {saveModal &&
                <Member setSaveModal={setSaveModal} answers={answers} type={2}/>
            }
            <TemporaryStorage NextSave={NextSave}/>
        </>
    );
}

export default Evaluation_2;


