import React, {useEffect, useState} from "react";
import {Btn, Container, Wrap} from "../../Assets/css/Common";
import { API } from "../../Utils/Api";
import Head from "../../Components/Head";
import Evaluation_1 from "./Result_1";
import Evaluation_2 from "./Result_1";
import close from "../../Assets/image/close.png";
import {useLocation, useNavigate} from "react-router-dom";
import History from "../../Components/History";
import Flowchart from "../../Components/Flowchart";
import LinkError from "../../Components/LinkError";
import {createBrowserHistory} from "history";
const history = createBrowserHistory();

function Result(props) {
    const navigate = useNavigate();
    const [next, setNext] = useState(0);
    const [SurveyNum, setSurveyNum] = useState(sessionStorage.getItem('surveyNum'));
    const [CompanyNum, setCompanyNum] = useState(sessionStorage.getItem('companyNum'));
    const [searchname, setSearchname] = useState(sessionStorage.getItem('searchname'));
    const [searchid,setSearchid] = useState(sessionStorage.getItem('searchid'));
    const [hp, setHp] = useState(sessionStorage.getItem('hp'));
    const [name, setName] = useState(sessionStorage.getItem('name'));
    const [answers, setAnswers] = useState([]);
    const [answers2, setAnswers2] = useState([]);
    const [submitModal, setSubmitModal] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleNavigation = (action) => {
            if (action === "POP") {
                if(window.confirm('페이지를 벗어나면 작성된 내용은 저장되지 않습니다.\n이 페이지에서 벗어나시겠습니까?')){
                    navigate('/');
                } else {
                    navigate(location.pathname, { replace: false});
                }
            }
        };

        const unlisten = history.listen(({ action }) => handleNavigation(action));

        return () => unlisten();
    }, [navigate, history]);

    // console.log('2',answers);
    const NextSubmit = async (e) => {
        const newArray = answers.map(item => ({
            ...item,
            gubun: "SE"
        }));

        // console.log('newArray',newArray);
        if (e === 'TemporaryStorage') {
            const postData = {
                "survey_id": parseInt(SurveyNum),
                "company_target_id": parseInt(searchid),
                "rater_company_id": parseInt(CompanyNum),
                "phone": hp,
                "name": name,
                "temporary": newArray,
            };

            // console.log(postData);
            try {
                const res = await API.post(`/users/survey/temporary-save`, postData);
                // console.log('서버 응답:', res);
                // console.log(postData);
                if (res.data.status === true) {
                    if (e === 'TemporaryStorage') {
                        sessionStorage.setItem('ArraySearchName', null);
                        setSaveModal(true);
                    } else {
                        console.log('error');
                        sessionStorage.setItem('ArraySearchName', null);
                    }
                } else {
                    console.log('error');
                }
            } catch (error) {
                console.error('에러 발생:', error);
            }
        }
    };

    const handleSubmit = async () => {
        const postData = {
            "survey_id": SurveyNum,
            "rater_company_id":CompanyNum,
            "se_answer": answers,
            "mp_answer": answers2,
            "phone": hp,
            "name": name,
        };

        try {
            // console.log("postData", postData);
            const res = await API.post(`/users/survey/assessment-complete`, postData);
            // console.log(res);
            if(res.data.status === true){
                setSubmitModal(true);
            }else{
                console.log('error');
            }
        } catch (error) {
            console.error('에러 발생:', error);
        }
    };

    const SubmitCheck = () => {
        navigate(`/complete`);
    }

    const SaveSubmit = () => {
        // navigate(`/survey/${SurveyNum?.[0]}/${CompanyNum?.[0]}`);
        setSaveModal(false);
    }

    return (
        <>
        {sessionStorage.getItem('pageStatus') === '' ?
        <div>
            <Head />
            <Flowchart check={3}/>
            <Wrap>
                <>
                {next !== 1 ?
                    <Evaluation_1 hp={hp} setHp={setHp} setName={setName} name={name} setNext={setNext} SurveyNum={SurveyNum} CompanyNum={CompanyNum} setAnswers={setAnswers} answers={answers} NextSubmit={NextSubmit} handleSubmit={handleSubmit}/>
                    :
                    <Evaluation_2 SurveyNum={SurveyNum} setAnswers={setAnswers} handleSubmit={handleSubmit}/>
                }
                 {submitModal &&
                    <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                        <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                        <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                            <div className="flex justify-end items-center pb-1" >
                                <img src={close} alt='취소' className="cursor-pointer md:w-auto w-5 md:h-auto h-5" onClick={SubmitCheck}/>
                            </div>
                            <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">주관식 평가 완료</p>
                            <p className="text-sm md:text-base text-slate-600 tracking-normal md:tracking-tighter text-center md:pb-10 pb-5">응답 내용이 제출되었습니다.</p>
                            <Btn onClick={SubmitCheck} width={'100%'} padding={'10px 0'} background={'#7D1825'} border={'1px solid #7D1825'} color={'#fff'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} type={"button"}>확인</Btn>
                        </div>
                    </div>
                }
                </>
                <History />
            </Wrap>
            <div className="w-full text-center pb-4">
                <p className="md:text-sm text-[10px] text-[#4E5968]">Copyright ⓒ 2024 law firm rating. All Rights Reserved</p>
            </div>
            {saveModal &&
                <div className="fixed w-full top-0 left-0 flex items-center justify-center h-screen z-10">
                    <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                    <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                        <div className="flex justify-end items-center pb-1" >
                            <img src={close} alt='취소' className="cursor-pointer md:w-auto w-5 md:h-auto h-5" onClick={SaveSubmit}/>
                        </div>
                        <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">평가 임시저장 완료</p>
                        <p className="pb-4 text-[#475569] text-sm md:text-base font-medium text-center"><span className="text-[#7D1825] font-bold">주관식</span> 평가가<br />임시 저장 되었습니다.</p>
                        <div className="flex justify-between items-center">
                            <Btn onClick={SaveSubmit} width={'100%'} padding={'10px 0'} background={'#7D1825'} color={'#fff'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'} type={"button"}>확인</Btn>
                        </div>
                    </div>
                </div>
                }
            </div>
        :
            <LinkError />
        }
        </>
    );
}

export default Result;
