import React, {useEffect, useState} from "react";
import {Btn, Container} from "../Assets/css/Common";
// import icon from '../Assets/image/history.png';
import icon from '../Assets/image/history2.png';
import check from '../Assets/image/checkon.png';
// import close from '../Assets/image/close.png';
import { useNavigate } from "react-router-dom";
import {API} from "../Utils/Api";
import close from "../Assets/image/close.png";

function History() {
    const [hp, setHp] = useState(sessionStorage.getItem('hp'));
    const [name, setName] = useState(sessionStorage.getItem('name'));
    const [load, setLoad] = useState(sessionStorage.getItem('load'))
    const [data, setData] = useState(JSON.parse(sessionStorage.getItem('ArraySearchName')) || []);
    const [lastDate, setLastDate] = useState();
    const [modal, setModal] = useState(false);
    const [lastmodal, setLastModal] = useState(sessionStorage.getItem('savemodal') !== '' ? false : true);
    const [bgShow, setBgShow] = useState(false);

    const getData = async () => {  
        const res = await API.get(`/users/survey/get-temporary/${sessionStorage.getItem('surveyNum')}?name=${name}&phone=${hp}&rater_company_id=${sessionStorage.getItem('companyNum')}`);
        if(res.data.status === true){
            setData(res.data?.result);
            setLastDate(res.data?.last_save_date || '');
            // setLastDate('');
        }
    };

    useEffect(() => {
        if(hp && name) {
            getData();
        }
         // getData();
    },[]);

    const OnModal = () => {
        if(modal){
            setModal(false);
            setTimeout(()=>{  setBgShow(false); }, 700);
        }else{
            setBgShow(true);
            setModal(true);
        }
    }

    const SaveDate = () => {
        sessionStorage.setItem('savemodal',false);
        setLastModal(false)
    }
// console.log('data',data);
    return (
        <>
            <div className="flex justify-end">
                <div className="z-0 w-[50px] md:w-[80px] h-[50px] md:h-[80px] fixed bottom-5 mr-0 md:-mr-28 flex flex-col justify-center items-center bg-[#7D1825] rounded-full cursor-pointer" onClick={OnModal}>
                    <img src={icon} alt={'history'} className="w-[18px] md:w-[25px]"/>
                    <p className="text-white text-[9px] md:text-xs font-medium text-center pt-1">평가내역</p>
                </div>
            </div>


            <div className={`fixed w-full top-0 left-0 items-end md:items-center justify-center h-screen flex ${bgShow ? 'z-0' : '-z-10'}`}>
                <div className={`h-screen bg-black w-full opacity-30 absolute z-10 ${modal ? 'flex' : 'hidden'}`}></div>
                <div className={`bg-white absolute z-20 w-[100%] md:w-[372px] p-5 md:p-8 rounded-tl-lg rounded-tr-lg md:rounded-lg ${modal ? 'translate-y-0' : 'translate-y-96 md:hidden'} duration-700 md:duration-0`}>
                    <div className="flex justify-end items-center pb-1" >
                        <img src={close} alt='취소' className="cursor-pointer md:w-auto w-5 md:h-auto h-5" onClick={OnModal}/>
                    </div>
                    <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">진행중인 평가 내역</p>
                    <p className="text-sm md:text-base text-slate-600 tracking-normal md:tracking-tighter text-center md:pb-10 pb-5">진행중인 평가 내역을 안내드립니다.</p>
                    <div className="mb-6 max-h-52 overflow-auto scrollbar-hide">
                        {data.map((list,key) => (
                        <div key={key} className="flex justify-between items-center px-3 py-2 border-[#7D1825] border rounded-md mb-3">
                            <p>{list?.law_firm_name}</p>
                            <img src={check} alt={'체크'}/>
                        </div>
                        ))}
                    </div>
                    <Btn onClick={OnModal} background={'#7D1825'} border={'1px solid #7D1825'} color={'#fff'} width={'100%'} padding={'10px 0'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'}>확인</Btn>
                </div>
            </div>


            {lastDate &&
                <div className={`fixed w-full top-0 left-0 items-center justify-center h-screen ${lastmodal ? 'flex' : 'hidden'}`}>
                    <div className="h-screen bg-black w-full opacity-30 absolute z-10"></div>
                    <div className="bg-white absolute z-20 w-[80%] md:w-[372px] p-5 md:p-8 rounded-lg">
                        <div className="flex justify-end items-center pb-1" >
                            <img src={close} alt='취소' onClick={SaveDate} className="cursor-pointer md:w-auto w-5 md:h-auto h-5"/>
                        </div>
                        <p className="text-base md:text-xl text-slate-900 font-bold text-center md:pb-4 pb-2.5">임시저장 불러오기</p>
                        <p className="text-sm md:text-base text-slate-600 tracking-normal md:tracking-tighter text-center md:pb-10 pb-5"><span className="font-bold text-[#7D1825]">20{lastDate}</span>에 저장된 설문입니다.</p>
                        {/*<p className="text-sm md:text-base text-slate-600 tracking-normal md:tracking-tighter text-center md:pb-10 pb-5">내가 평가한 로펌들의 리스트는 우측하단의<br/>'평가 내역'을 클릭하면 확인할 수 있습니다.</p>*/}
                        <Btn onClick={SaveDate} background={'#7D1825'} border={'1px solid #7D1825'} color={'#fff'} width={'100%'} padding={'10px 0'} radius={'6px'} size={'1rem'} sizemd={'0.875rem'} weight={'700'}>확인</Btn>
                    </div>
                </div>
            }
        </>

    );
}

export default History;
