import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./Pages/Home/index";
import Search from "./Pages/Search/index";
import Evaluation from "./Pages/Evaluation/index";
// import Result from "./Pages/Evaluation/EvaluationResult";
import Result from "./Pages/Result/index";
import Complete from "./Pages/Complete/index";
import ErrorPage from "./Components/ErrorPage";

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:surveyNum/:companyNum" element={<Home />} />
                <Route path="/search" element={<Search />} />
                <Route path="/evaluation" element={<Evaluation />} />
                <Route path="/result" element={<Result />} />
                <Route path="/complete" element={<Complete />} />
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </BrowserRouter>
    );
}
export default Router;
