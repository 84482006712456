import axios from 'axios';
const baseURL = process.env.REACT_APP_BASEURL;
export const API = axios.create({
  //    baseURL: 'http://localhost:8095/api',
 //    baseURL: 'http://172.30.1.41:8095/api',
  baseURL,
  // baseURL: 'http://3.34.8.181:8095/api',
    // baseURL: 'http://localhost:4000/api',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
});
